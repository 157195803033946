import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { TextField } from "@mui/material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  createCustomer,
  deleteCustomer,
  getAllCustomers,
} from "firestore/customers";
import Checkbox from "@mui/material/Checkbox";
import {
  isOnlyNumbers,
  isValidEmail,
  isValidLetters,
  isValidPhoneNumber,
} from "utils/helper";

export const Customers = () => {
  const [allCustomers, setAllCustomers] = useState<any[]>([]);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<string>("");
  const [invoicePerson, setInvoicePerson] = useState<string>("");
  const [address, setAddress] = useState<any>({
    address_line_1: "",
    address_line_2: "",
    admin_area_1: "",
    country_code: "DE",
    postal_code: "",
  });
  const [invoiceAddress, setInvoiceAddress] = useState<any>({
    address_line_1: "",
    address_line_2: "",
    admin_area_1: "",
    country_code: "DE",
    postal_code: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [hasDifferentAddress, setHasDifferentAddress] =
    useState<boolean>(false);

  useEffect(() => {
    getAllCustomers().then((res) => {
      setAllCustomers([...res]);
    });
  }, []);

  const getFullAddress = (address: any, person?: string) => {
    const fullAddress = [
      address?.address_line_1,
      address?.address_line_2,
      address?.admin_area_1,
      address?.admin_area_2,
      address?.postal_code,
      address?.country_code,
    ]
      .filter((e) => e)
      .join(", ");
    if (person) return [person, fullAddress].join(", ");
    else return fullAddress;
  };

  const columns: GridColDef[] = [
    { field: "accountNumber", headerName: "Account", minWidth: 80 },
    { field: "businessName", headerName: "Business Name", minWidth: 200 },
    { field: "contactPerson", headerName: "Contact Person", minWidth: 150 },
    { field: "address", headerName: "Address", sortable: false, minWidth: 300 },
    {
      field: "invoiceAddress",
      headerName: "Invoice Address",
      sortable: false,
      minWidth: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      minWidth: 140,
    },
    { field: "email", headerName: "Email", sortable: false, minWidth: 150 },
    { field: "note", headerName: "Note", sortable: false },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 20,
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              if (confirm(`Are you sure to delete ${params.row.businessName}`))
                deleteCustomer(params.row.id).then(() => {
                  getAllCustomers().then(async (res) => {
                    setAllCustomers([...res]);
                  });
                });
              //console.log(params.row.accountNumber);
            }}
          >
            X
          </button>
        );
      },
    },
  ];

  //renderCell: (params: GridRenderCellParams) => (
  //  <RenderExpandableCell {...params} />
  //)
  const rows = allCustomers.map((customer) => ({
    id: customer.id,
    accountNumber: customer.accountNumber,
    businessName: customer.name,
    contactPerson: customer.contactPerson,
    address: getFullAddress(customer.address),
    invoiceAddress: customer?.invoiceAddress
      ? getFullAddress(customer.invoiceAddress, customer?.invoicePerson)
      : getFullAddress(customer.address),
    phoneNumber: customer.phoneNumber,
    email: customer.email,
    note: customer?.note,
  }));

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Customers</h3>
        <div className="flex w-full h-full mx-12">
          <Accordion className="w-11/12 h-full">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <div className="text-seconadry">Create Customer</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full h-full pt-4 border-t-2">
                <div className="flex w-full h-full">
                  <div className="flex-row w-1/2 mx-2 h-fit">
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Account Number
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        error={!isOnlyNumbers(accountNumber)}
                      />
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Business Name
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                      />
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Contact Person
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        error={
                          !isValidLetters(contactPerson) &&
                          contactPerson.length > 0
                        }
                      />
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Phone Number
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        error={
                          !isValidPhoneNumber(phoneNumber) &&
                          phoneNumber.length > 0
                        }
                      />
                    </div>
                    <div className="w-full mt-12 row-flex">
                      <span className="flex w-full my-1">Shipping Address</span>
                      <div className="my-1">
                        <TextField
                          type="string"
                          size="small"
                          placeholder="Address Line 1 (Street Number/Name)"
                          className="flex w-full h-full"
                          value={address.address_line_1}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              address_line_1: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="my-1">
                        <TextField
                          type="string"
                          size="small"
                          placeholder="Address Line 2 (optional) (Suite/Apt number)"
                          className="flex w-full h-full"
                          value={address.address_line_2}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              address_line_2: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="flex w-full my-1">
                        <TextField
                          type="string"
                          size="small"
                          placeholder="Postal Code"
                          className="flex w-1/3 h-full"
                          value={address.postal_code}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              postal_code: e.target.value,
                            })
                          }
                        />

                        <TextField
                          type="string"
                          size="small"
                          placeholder="City"
                          className="flex w-full h-full"
                          value={address.admin_area_1}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              admin_area_1: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-row w-1/2 mx-2 h-fit">
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">Email</span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!isValidEmail(email) && email.length > 0}
                      />
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Note (optional)
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Invoice Person (optional)
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={invoicePerson}
                        onChange={(e) => setInvoicePerson(e.target.value)}
                      />
                    </div>
                    <div className="flex my-auto mt-10">
                      <Checkbox
                        checked={hasDifferentAddress}
                        onChange={() =>
                          setHasDifferentAddress(!hasDifferentAddress)
                        }
                        className="my-auto"
                      />
                      <span className="my-auto">
                        Invoice address is different from shipping address
                      </span>
                    </div>
                    {hasDifferentAddress && (
                      <div className="w-full my-1 row-flex">
                        <span className="flex w-full my-1">
                          Invoice Address (if different than Shipping Address)
                        </span>
                        <div className="my-1">
                          <TextField
                            type="string"
                            size="small"
                            placeholder="Address Line 1 (Street Number/Name)"
                            className="flex w-full h-full"
                            value={invoiceAddress.address_line_1}
                            onChange={(e) =>
                              setInvoiceAddress({
                                ...invoiceAddress,
                                address_line_1: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="my-1">
                          <TextField
                            type="string"
                            size="small"
                            placeholder="Address Line 2 (optional) (Suite/Apt number)"
                            className="flex w-full h-full"
                            value={invoiceAddress.address_line_2}
                            onChange={(e) =>
                              setInvoiceAddress({
                                ...invoiceAddress,
                                address_line_2: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex w-full my-1">
                          <TextField
                            type="string"
                            size="small"
                            placeholder="Postal Code"
                            className="flex w-1/3 h-full"
                            value={invoiceAddress.postal_code}
                            onChange={(e) =>
                              setInvoiceAddress({
                                ...invoiceAddress,
                                postal_code: e.target.value,
                              })
                            }
                          />
                          <TextField
                            type="string"
                            size="small"
                            placeholder="City"
                            className="flex w-full h-full"
                            value={invoiceAddress.admin_area_1}
                            onChange={(e) =>
                              setInvoiceAddress({
                                ...invoiceAddress,
                                admin_area_1: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={() => {
                    createCustomer({
                      accountNumber,
                      address,
                      invoiceAddress: hasDifferentAddress
                        ? invoiceAddress
                        : address,
                      contactPerson,
                      email,
                      note,
                      phoneNumber,
                      name: accountName,
                      invoicePerson,
                    }).then((res) => {
                      if (res) {
                        setAccountNumber("");
                        setAccountName("");
                        setContactPerson("");
                        setAddress({
                          address_line_1: "",
                          address_line_2: "",
                          admin_area_1: "",
                          country_code: "DE",
                          postal_code: "",
                        });
                        setInvoiceAddress({
                          address_line_1: "",
                          address_line_2: "",
                          admin_area_1: "",
                          country_code: "DE",
                          postal_code: "",
                        });
                        setPhoneNumber("");
                        setEmail("");
                        setNote("");
                        setInvoicePerson("");
                        setHasDifferentAddress(false);
                        alert("Customer Created");
                      } else {
                        alert("Invalid or Missing Input");
                      }
                      getAllCustomers().then((res) => {
                        setAllCustomers([...res]);
                      });
                    });
                  }}
                >
                  Create Customer
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="w-full h-full mt-4 xl:w-11/12 xl:mx-12">
          <DataGrid
            rows={rows}
            columns={columns}
            rowSelection={false}
            pageSizeOptions={[5, 10, 25, 50]}
            sortingOrder={["desc"]}
            sortModel={[{ field: "accountNumber", sort: "desc" }]}
          />
        </div>
      </div>
    </div>
  );
};

export default Customers;
