import cartSlice from "./redux/cart";
import { configureStore } from "@reduxjs/toolkit";
import orderSlice from "./redux/order";

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    order: orderSlice,
  },
});
