import {
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { app } from "./firestore";

const db = getFirestore(app);

export const createTransfer = async (transfer: any) => {
  const transferRef = collection(db, "transfers");
  const created = transfer?.created ?? new Date();
  const { items, note, fromLoc, toLoc } = transfer;

  await setDoc(doc(transferRef), {
    created,
    fromLocation: doc(db, "locations", fromLoc.id),
    toLocation: doc(db, "locations", toLoc.id),
    note,
    items,
  });

  items.forEach(async (item: any) => {
    const inStock = item?.inStock ?? {
      hamburg: 0,
      berlin: 0,
      hanover: 0,
      total: 0,
    };
    inStock[transfer.toLoc.name.toLowerCase()] += item.qty;
    inStock[transfer.fromLoc.name.toLowerCase()] -= item.qty;
    await updateDoc(doc(db, "products", item.id), {
      inStock,
    });
  });
};
