import {
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { app } from "./firestore";

const db = getFirestore(app);

export const createPurchase = async (purchase: any) => {
  const purchaseRef = collection(db, "purchases");
  const created = purchase?.created ?? new Date();
  const { items, note, fromLoc, toLoc } = purchase;
  const totalAmount = items.reduce(
    (prev: number, curr: any) => curr?.businessPrice * curr?.qty + prev,
    0
  );

  await setDoc(doc(purchaseRef), {
    created,
    fromLocation: doc(db, "locations", fromLoc.id),
    toLocation: doc(db, "locations", toLoc.id),
    note,
    items,
    totalAmount,
  });

  items.forEach(async (item: any) => {
    const inStock = item?.inStock ?? {
      hamburg: 0,
      berlin: 0,
      hanover: 0,
      total: 0,
    };
    inStock[purchase.toLoc.name.toLowerCase()] += item.qty;
    inStock.total += item.qty;
    await updateDoc(doc(db, "products", item.id), {
      inStock,
    });
  });
};
