import { collection, getDocs, getFirestore } from "firebase/firestore";

import { app } from "./firestore";

export const getShippingInfo = async () => {
  const db = getFirestore(app);
  const productRef = collection(db, "shipping");
  const querySnapshot = await getDocs(productRef);
  const allCountries: any[] = [];
  querySnapshot.forEach((doc) => {
    allCountries.push(doc.data());
  });
  return allCountries;
};
