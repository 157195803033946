export const DatenschutzerklarungView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Datenschutzerklärung</h3>
        <p className="font-bold">1. Datenschutz auf einen Blick</p>
        <p className="font-bold">Allgemeine Hinweise</p>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren Personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <p className="font-bold">Datenerfassung auf dieser Website</p>
        <p>
          {" "}
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
        </p>
        <p>Wie erfassen wir Ihre Daten?</p>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
        <p>Wofür nutzen wir Ihre Daten?</p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten.
        </p>
        <p className="font-bold">
          Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet
          werden.
        </p>
        <p>Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie
          zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an
          uns wenden.
        </p>
        <p className="font-bold">2. Hosting</p>
        <p className="font-bold">
          Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          Firebase Hosting
        </p>
        <p>
          Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street,
          Dublin 4, Irland (nachfolgend „Firebase“). Firebase Hosting ein Tool
          zum Erstellen und zum Hosten von Webseiten. Wenn Sie unsere Website
          besuchen, werden mit Hilfe von Firebase Hosting das Nutzerverhalten,
          die Besucherquellen, die Region der Websitebesucher und die
          Besucherzahlen analysiert. Firebase Hosting speichert Cookies auf
          Ihrem Browser, die für die Darstellung der Website und zur
          Gewährleistung der Sicherheit erforderlich sind (notwendige Cookies).
          Die Daten, die über Firebase Hosting erfasst werden, können auf
          verschiedenen Servern weltweit gespeichert werden. Die Server von
          Firebase Hosting stehen u. a. in den USA.
        </p>
        <p>
          Details entnehmen Sie der Datenschutzerklärung von Firebase Hosting:
        </p>
        <p>https://firebase.google.com/support/privacy.</p>
        <p>
          Die Datenübertragung in die USA und sonstige Drittstaaten wird laut
          Firebase Hosting auf die Standardvertragsklauseln der EU-Kommission
          bzw. vergleichbare Garantien nach Art. 46 DSGVO gestützt. Details
          finden Sie hier:
        </p>
        <p>https://firebase.google.com/support/privacy.</p>
        <p>
          Die Verwendung von Firebase Hosting erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
          möglichst zuverlässigen Darstellung unserer Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
          Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
          den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
          Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <p>
          Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
          Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
          Europäischen Union und den USA, der die Einhaltung europäischer
          Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
          soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
          diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:
        </p>
        <p>
          https://www.dataprivacyframework.gov/s/participantsearch/participantdetail?contact=true&id=a2zt0000000GnbGAAS&status=Active
        </p>
        <p className="font-bold">Auftragsverarbeitung</p>
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung
          des oben genannten Dienstes geschlossen. Hierbei handelt es sich um
          einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gewährleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </p>
        <p className="font-bold">
          3. Allgemeine Hinweise und Pflichtinformationen
        </p>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <p className="font-bold">​Hinweis zur verantwortlichen Stelle</p>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          An den Pappeln 4a
          <br />
          21521 Wohltorf
          <br />
          Telefon: +49 (0)4104 9621236
          <br />
          E-Mail: info@sojuhalle.com
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <p className="font-bold">Speicherdauer</p>
        <p>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <p>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
          dieser Website Sofern Sie in die Datenverarbeitung eingewilligt haben,
          verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6
          Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
          Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
          einer ausdrücklichen Einwilligung in die Übertragung personenbezogener
          Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf
          Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die
          Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
          Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt
          die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG.
          Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs.
          1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf
          Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
          ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
          1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
          Rechtsgrundlagen wird in den folgenden Absätzen dieser
          Datenschutzerklärung informiert.
        </p>
        <p className="font-bold">Empfänger von personenbezogenen Daten</p>
        <p>
          Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen
          externen Stellen zusammen. Dabei ist teilweise auch eine Übermittlung
          von personenbezogenen Daten an diese externen Stellen erforderlich.
        </p>
        <p>
          Wir geben personenbezogene Daten nur dann an externe Stellen weiter,
          wenn dies im Rahmen einer Vertragserfüllung erforderlich ist, wenn wir
          gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten an
          Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs.
          1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige
          Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
          Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden
          nur auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung
          weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über
          gemeinsame Verarbeitung geschlossen.
        </p>
        <p className="font-bold">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </p>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p className="font-bold">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen
        </p>
        <p>Direktwerbung (Art. 21 DSGVO)</p>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
          WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
          DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
          PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
          WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
          ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
          (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <p>Beschwerderecht bei der zuständigen Aufsichtsbehörde</p>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <p className="font-bold">Recht auf Datenübertragbarkeit</p>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <p className="font-bold">Auskunft, Berichtigung und Löschung</p>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </p>
        <p className="font-bold">Recht auf Einschränkung der Verarbeitung​</p>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </p>
        <p>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen. Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
          Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
          personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
          Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
          benötigen, haben Sie das Recht, statt der Löschung die Einschränkung
          der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie
          einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
          Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
          Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
          das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
          Daten zu verlangen.
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <p className="font-bold">4. Soziale Medien</p>
        <p className="font-bold">Social-Media-Elemente mit Shariff</p>
        <p>
          Auf dieser Website werden Elemente von sozialen Medien verwendet (z.
          B. Facebook, X, Instagram, Pinterest, XING, LinkedIn, Tumblr). Die
          Social-Media-Elemente können Sie in der Regel anhand der jeweiligen
          Social-Media-Logos erkennen. Um den Datenschutz auf dieser Website zu
          gewährleisten, verwenden wir diese Elemente nur zusammen mit der
          sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die auf
          dieser Website integrierten Social-Media-Elemente Ihre
          personenbezogenen Daten schon beim ersten Betreten der Seite an den
          jeweiligen Anbieter übertragen.
        </p>
        <p>
          Erst wenn Sie das jeweilige Social-Media-Element durch Anklicken der
          zugehörigen Schaltfläche aktivieren, wird eine direkte Verbindung zum
          Server des Anbieters hergestellt (Einwilligung). Sobald Sie das
          Social- Media-Element aktivieren, erhält der jeweilige Anbieter die
          Information, dass Sie mit Ihrer IP-Adresse diese Website besucht
          haben. Wenn Sie gleichzeitig in Ihrem jeweiligen Social-Media-Account
          (z. B. Facebook) eingeloggt sind, kann der jeweilige Anbieter den
          Besuch dieser Website Ihrem Benutzerkonto zuordnen.
        </p>
        <p>
          Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art.
          6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG dar. Diese Einwilligung
          können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Der
          Einsatz des Dienstes erfolgt, um die gesetzlich vorgeschriebenen
          Einwilligungen für den Einsatz bestimmter Technologien einzuholen.
          Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <p className="font-bold">Facebook</p>
        <p>
          Auf dieser Website sind Elemente des sozialen Netzwerks Facebook
          integriert. Anbieter dieses Dienstes ist die Meta Platforms Ireland
          Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
          werden nach Aussage von Facebook jedoch auch in die USA und in andere
          Drittländer übertragen. Eine Übersicht über die Facebook
          Social-Media-Elemente finden Sie hier:
        </p>
        <p>https://developers.facebook.com/docs/plugins/?locale=de_DE</p>

        <p>
          Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung
          zwischen Ihrem Endgerät und dem Facebook-Server hergestellt. Facebook
          erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese
          Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken,
          während Sie in Ihrem Facebook- Account eingeloggt sind, können Sie die
          Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch
          kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
          Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
          vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
          erhalten. Weitere Informationen hierzu finden Sie in der
          Datenschutzerklärung von Facebook unter:
        </p>
        <p>https://de-de.facebook.com/privacy/explanation</p>
        <p>
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
          Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des
          Dienstes auf Grundlage unseres berechtigten Interesses an einer
          möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
        </p>
        <p>
          Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten
          auf unserer Website erfasst und an Facebook weitergeleitet werden,
          sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
          Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
          Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
          Erfassung der Daten und deren Weitergabe an Facebook. Die nach der
          Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil
          der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
          Verpflichtungen wurden in einer Vereinbarung über gemeinsame
          Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
          unter:
        </p>
        <p>​https://www.facebook.com/legal/controller_addendum</p>
        <p>
          Laut dieser Vereinbarung sind wir für die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
          datenschutzrechtlich sichere Implementierung des Tools auf unserer
          Website verantwortlich. Für die Datensicherheit der Facebook-Produkte
          ist Facebook verantwortlich. Betroffenenrechte (z. B.
          Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten
          können Sie direkt bei Facebook geltend machen. Wenn Sie die
          Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
          an Facebook weiterzuleiten.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:​
        </p>
        <p>https://www.facebook.com/legal/EU_data_transfer_addendum</p>
        <p>https://de-de.facebook.com/help/566994660333381</p>
        <p>https://www.facebook.com/policy.php</p>

        <p>
          Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
          Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
          Europäischen Union und den USA, der die Einhaltung europäischer
          Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
          soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
          diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:
        </p>
        <p>
          https://www.dataprivacyframework.gov/s/participant-search/participantdetail?contact=true&id=a2zt0000000GnywAAC&status=Active
        </p>
        <p className="font-bold">Instagram</p>
        <p>
          Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden.
          Diese Funktionen werden angeboten durch die Meta Platforms Ireland
          Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.
        </p>
        <p>
          Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung
          zwischen Ihrem Endgerät und dem Instagram-Server hergestellt.
          Instagram erhält dadurch Informationen über den Besuch dieser Website
          durch Sie. Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können
          Sie durch Anklicken des Instagram-Buttons die Inhalte dieser Website
          mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
          Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf
          hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
          Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des
          Dienstes auf Grundlage unseres berechtigten Interesses an einer
          möglichst umfassenden Sichtbarkeit in den Sozialen Medien. Soweit mit
          Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer
          Website erfasst und an Facebook bzw. Instagram weitergeleitet werden,
          sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
          Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
          Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
          Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram.
          Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook bzw.
          Instagram ist nicht Teil der gemeinsamen Verantwortung.
        </p>
        <p>
          Die uns gemeinsam obliegenden Verpflichtungen wurden in einer
          Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut
          der Vereinbarung finden Sie unter:
        </p>
        <p>https://www.facebook.com/legal/controller_addendum</p>
        <p>
          Laut dieser Vereinbarung sind wir für die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook- bzw.
          Instagram-Tools und für die datenschutzrechtlich sichere
          Implementierung des Tools auf unserer Website verantwortlich. Für die
          Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook
          verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen)
          hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten
          können Sie direkt bei Facebook geltend machen. Wenn Sie die
          Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
          an Facebook weiterzuleiten.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:
        </p>
        <p>https://www.facebook.com/legal/EU_data_transfer_addendum</p>
        <p>https://de-de.facebook.com/help/566994660333381</p>
        <p>https://www.facebook.com/policy.php</p>
        <p>
          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
          von Instagram:
        </p>
        <p>https://privacycenter.instagram.com/policy/</p>
        <p>
          Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
          Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
          Europäischen Union und den USA, der die Einhaltung europäischer
          Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
          soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
          diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:
        </p>
        <p>
          https://www.dataprivacyframework.gov/s/participant-search/participantdetail?contact=true&id=a2zt0000000GnywAAC&status=Active
        </p>
        <p className="font-bold">5. Newsletter</p>
        <p className="font-bold">Newsletterdaten</p>
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine EMail- Adresse sowie Informationen,
          welche uns die Überprüfung gestatten, dass Sie der Inhaber der
          angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
          für den Versand der angeforderten Informationen und geben diese nicht
          an Dritte weiter. Die Verarbeitung der in das
          Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die
          erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse
          sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
          widerrufen, etwa über den „Austragen“-Link im Newsletter. Die
          Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
          vom Widerruf unberührt.
        </p>
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
          uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
          Abbestellung des Newsletters oder nach Zweckfortfall aus der
          Newsletterverteilerliste gelöscht. Wir behalten uns vor,
          E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
          im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
          DSGVO zu löschen oder zu sperren. Daten, die zu anderen Zwecken bei
          uns gespeichert wurden, bleiben hiervon unberührt.
        </p>
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
          einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
          Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für
          diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt.
          Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
          Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
          (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
          Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können
          der Speicherung widersprechen, sofern Ihre Interessen unser
          berechtigtes Interesse überwiegen.
        </p>
        <p className="font-bold">6. Plugins und Tools</p>
        <p className="font-bold">Google Maps</p>
        <p>
          Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
          Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin
          4, Irland. Zur Nutzung der Funktionen von Google Maps ist es
          notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in
          der Regel an einen Server von Google in den USA übertragen und dort
          gespeichert.
        </p>

        <p>
          Der Anbieter dieser Seite hat keinen Einfluss auf diese
          Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum
          Zwecke der einheitlichen Darstellung der Schriftarten Google Fonts
          verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten
          Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
          anzuzeigen.
        </p>
        <p>
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
          erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
          1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
          Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
          des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:
        </p>
        <p>https://privacy.google.com/businesses/gdprcontrollerterms/ und</p>
        <p>https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</p>
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Google:
        </p>
        <p>https://policies.google.com/privacy?hl=de</p>
        <p>
          Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
          Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
          Europäischen Union und den USA, der die Einhaltung europäischer
          Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
          soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
          diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:
        </p>
        <p>
          https://www.dataprivacyframework.gov/s/participant-search/participantdetail?contact=true&id=a2zt000000001L5AAI&status=Active​
        </p>
      </div>
    </div>
  );
};

export default DatenschutzerklarungView;
