import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: [],
  reducers: {
    createOrder(state: any, action: any) {
      state = action.payload;
    },
    clearOrder(state: any) {
      state = null;
      return state;
    },
  },
});

export const { createOrder, clearOrder } = orderSlice.actions;
export default orderSlice.reducer;
