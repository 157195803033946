export const AGBView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Allgemeine Geschäftsbedingungen</h3>
        <p>Allgemeine Verkaufsbedingungen Online-Shop der Soju Halle GmbH ​</p>
        <p className="font-bold">§ 1 Geltungsbereich</p>
        <p>
          (1) Diese Allgemeinen Verkaufsbedingungen (im Folgenden: AGB) gelten
          für alle über unseren Online-Shop auf dem Internet – Marktplatz
          ,,sojuhalle.com“ geschlossenen Verträge zwischen uns, der ​
        </p>
        <p>Soju Halle GmbH,</p>
        <p>
          An den Pappeln 4a
          <br />
          21521 Wohltorf, Deutschland
          <br />
          Geschäftsführer: Young Sam Hur
          <br />
          Registergericht: HRB 24430 HLUSt-IdNr.: DE365228391
          <br />
          E-Mailadresse: info@sojuhalle.com
          <br />
          und Ihnen als unseren Kunden. Die AGB gelten unabhängig davon, ob Sie
          Verbraucher, Unternehmer oder Kaufmann sind.
        </p>
        <p>
          ​(2) Alle zwischen Ihnen und uns im Zusammenhang mit dem Kaufvertrag
          getroffenen Vereinba-rungen ergeben sich insbesondere aus diesen
          Verkaufsbedingungen, unserer schriftlichen Auf-tragsbestätigung und
          unserer Annahmeerklärung.
        </p>
        <p>
          (3) Maßgebend ist die jeweils bei Abschluss des Vertrags gültige
          Fassung der AGB.
        </p>
        <p>
          (4) Abweichende Bedingungen des Kunden akzeptieren wir nicht. Dies
          gilt auch, wenn wir der Einbeziehung nicht ausdrücklich widersprechen.
          ​
        </p>
        <p className="font-bold">§ 2 Vertragsschluss</p>
        <p>
          (1) Die Präsentation und Bewerbung von Artikeln in unserem Online-Shop
          stellt kein bindendes Angebot zum Abschluss eines Kaufvertrags dar.
        </p>
        <p>
          (2) Mit dem Absenden einer Bestellung über den Online-Shop durch
          Anklicken des Buttons „Jetzt zahlungspflichtig bestellen“ geben Sie
          eine rechtsverbindliche Bestellung ab. Sie sind an die Bestellung für
          die Dauer von zwei (2) Wochen nach Abgabe der Bestellung gebunden; Ihr
          gegebenenfalls nach § 3 bestehendes Recht, Ihre Bestellung zu
          widerrufen, bleibt hiervon unberührt.
        </p>
        <p>
          (3) Wir werden den Zugang Ihrer über unseren Online-Shop abgegebenen
          Bestellung unver-züglich per E-Mail bestätigen. In einer solchen
          E-Mail liegt noch keine verbindliche Annahme der Bestellung, es sei
          denn, darin wird neben der Bestätigung des Zugangs zugleich die
          Annahme erklärt.
        </p>
        <p>
          (4) Ein Vertrag kommt erst zustande, wenn wir Ihre Bestellung durch
          eine Annahmeerklärung oder durch die Lieferung der bestellten Artikel
          annehmen.
        </p>
        <p>
          (5) Sollte die Lieferung der von Ihnen bestellten Ware nicht möglich
          sein, etwa weil die entspre-chende Ware nicht auf Lager ist, sehen wir
          von einer Annahmerklärung ab. In diesem Fall kommt ein Vertrag nicht
          zustande. Wir werden Sie darüber unverzüglich informieren und bereits
          erhaltene Gegenleistungen unverzüglich zurückerstatten.
        </p>
        <p className="font-bold">§ 3 Widerrufsrecht</p>
        <p>
          (1) Wenn Sie Verbraucher sind (also eine natürliche Person, die die
          Bestellung zu einem Zweck abgibt, der weder Ihrer gewerblichen noch
          selbständigen beruflichen Tätigkeit zugerechnet werden kann), steht
          Ihnen nach Maßgabe der gesetzlichen Bestimmungen ein Widerrufsrecht
          zu.
        </p>
        <p>
          (2) Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer
          (1) Gebrauch, so haben Sie die regelmäßigen Kosten der Rücksendung zu
          tragen.
        </p>
        <p>
          (3) Im Übrigen gelten für das Widerrufsrecht die Regelungen, die im
          Einzelnen wiedergegeben sind in der folgenden
        </p>
        <p className="font-bold">Widerrufsbelehrung</p>
        <p>Widerrufsrecht</p>​
        <p>
          ​ Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
          diesen Vertrag zu widerrufen.
        </p>
        <p>
          Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder
          ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die
          Waren in Besitz genommen haben bzw. hat.
        </p>
        <p>
          Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Addresse: An den
          Pappeln 4a, 21521 Wohltorf, Telefonnummer: +49 (0)410 4962 1236,
          E-Mailadresse: info@sojuhalle.com) mittels einer eindeutigen Erklärung
          (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über
          Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
          können dafür das als Anlage 1 (AGB) beigefügte
          Muster-Widerrufsformular verwenden, das jedoch nicht vorge-schrieben
          ist.
        </p>
        <p>
          ​ Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
          Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
          Widerrufsfrist absenden.
        </p>
        <p>Folgen des Widerrufs</p>
        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
          die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
          Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
          eine andere Art der Lieferung als die von uns angebotene, günstigste
          Standardlieferung gewählt haben), unverzüglich und spätestens binnen
          vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
          Ihren Widerruf dieses Vertrags bei uns einge-gangen ist. Für diese
          Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
          ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
          wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
          Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die
          Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben
          oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
          zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
        </p>
        <p>
          Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
          vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
          Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die
          Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von
          vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der
          Rücksendung der Waren.
        </p>
        <p>
          Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen,
          wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
          Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang
          mit ihnen zurückzuführen ist.
        </p>
        <p>Ende der Widerrufsbelehrung</p>
        <p>(4) Das Widerrufsrecht besteht nicht bei Fernabsatzverträgen</p>
        <p className="pl-2">
          (a) zur Lieferung von Waren, die schnell verderben können oder deren
          Verfallsdatum überschrit-ten würde,
        </p>
        <p className="pl-2">
          (b) zur Lieferung alkoholischer Getränke, deren Preis bei
          Vertragsschluss vereinbart wurde, die wir aber vereinbarungsgemäß
          frühestens dreißig Tage nach Vertragsschluss liefern werden können und
          deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die
          wir keinen Einfluss haben.
        </p>
        <p className="font-bold">
          § 4 Lieferbedingungen und Vorbehalt der Vorkassezahlung
        </p>
        <p>
          (1) Wir sind zu Teillieferungen berechtigt, soweit dies für Sie
          zumutbar ist.
        </p>
        <p>
          (2) Die Lieferfrist beträgt circa fünf (5) Werktage, soweit nichts
          anderes vereinbart wurde. Sie beginnt – vorbehaltlich der Regelung in
          Abs. (3) – mit Vertragsschluss.
        </p>
        <p>
          (3) Bei Bestellungen von Kunden mit Wohn- oder Geschäftssitz im
          Ausland oder bei begründeten Anhaltspunkten für ein
          Zahlungsausfallrisiko behalten wir uns vor, erst nach Erhalt des
          Kaufpreises nebst Versandkosten zu liefern (Vorkassevorbehalt). Falls
          wir von dem Vorkassevorbehalt Gebrauch machen, werden wir Sie
          unverzüglich unterrichten. In diesem Fall beginnt die Lieferfrist mit
          Bezahlung des Kaufpreises und der Versandkosten.
        </p>
        <p className="font-bold">§ 5 Preise und Versandkosten</p>
        <p>
          (1) Sämtliche Preisangaben in unserem Online-Shop sind Bruttopreise
          inklusive der gesetzlichen Umsatzsteuer und verstehen sich zuzüglich
          anfallender Versandkosten.
        </p>
        <p>
          (2) Die Versandkosten sind in unseren Preisangaben in unserem
          Online-Shop angegeben. Der Preis einschließlich Umsatzsteuer und
          anfallender Versandkosten wird außerdem in der Bestell-maske
          angezeigt, bevor Sie die Bestellung absenden.
        </p>
        <p>
          (3) Wenn wir Ihre Bestellung gemäß § 4 Abs. 1 durch Teillieferungen
          erfüllen, entstehen Ihnen nur für die erste Teillieferung
          Versandkosten. Erfolgen die Teillieferungen auf Ihren Wunsch,
          berechnen wir für jede Teillieferung Versandkosten.
        </p>
        <p>
          (4) Wenn Sie Ihre Vertragserklärung wirksam gemäß § 3 widerrufen,
          können Sie unter den gesetzlichen Voraussetzungen die Erstattung
          bereits bezahlter Kosten für den Versand zu Ihnen (Hinsendekosten)
          verlangen (vgl. zu sonstigen Widerrufsfolgen § 3 Abs. 3).
        </p>
        <p className="font-bold">
          ​§ 6 Zahlungsbedingungen und Aufrechnung und Zurückbehaltungsrecht
        </p>
        <p>
          (1) Sie haben die Möglichkeit, den Kaufpreis über Paypal zu bezahlen.
        </p>
        <p>
          (2) Bei Zahlung per PayPal (www.paypal.com) erfolgt die Belastung des
          Rechnungsbetrags, wenn wir Ihre Bestellung annehmen. Die Ware wird für
          Sie 15 Tage reserviert und nach Geldeingang auf unserem Konto an Ihre
          Lieferadresse, ggfs. unter Berücksichtigung der von Ihnen angegebenen
          Lieferzeit, versendet.
        </p>
        <p>
          (3) Sie sind nicht berechtigt, gegenüber unseren Forderungen
          aufzurechnen, es sei denn, Ihre Gegenansprüche sind rechtskräftig
          festgestellt oder unbestritten. Sie sind zur Aufrechnung gegenüber
          unseren Forderungen auch berechtigt, wenn Sie Mängelrügen oder
          Gegenansprüche aus demselben Kaufvertrag geltend machen.
        </p>
        <p>
          (4) Als Käufer dürfen Sie ein Zurückbehaltungsrecht nur dann ausüben,
          wenn Ihr Gegenanspruch aus demselben Kaufvertrag herrührt.
        </p>
        <p className="font-bold">§ 7 Eigentumsvorbehalt</p>
        <p>
          Die gelieferte Ware bleibt bis zur vollständigen Bezahlung des
          Kaufpreises in unserem Eigentum.
        </p>
        <p className="font-bold">§ 8 Jugendschutzgesetz</p>
        <p>
          Wir möchten im Sinne der Erfüllung des Jugendschutzgesetzes darauf
          hinweisen, dass wir keine alkoholischen Getränke an Personen unter 18
          Jahren verkaufen.
        </p>
        <p>
          Sofern Sie noch keine 18 Jahre alt sind, erhalten Sie deshalb keinen
          Zugang zu unserem Bestellsystem.
        </p>
        <p>
          Mit Ihrer Bestellung bestätigen Sie, dass Sie über 18 Jahre alt sind.
          Darüber hinaus sind die Zusteller verpflichtet, im Zweifel die
          Volljährigkeit durch das Vorzeigen des Personalausweises bestätigen zu
          lassen.​
        </p>
        <p className="font-bold">§ 9 Gewährleistung</p>
        <p>
          (1) Wir haften für Sach- oder Rechtsmängel gelieferter Artikel nach
          den geltenden gesetzlichen Vorschriften, insbesondere §§ 434 ff. BGB.
          Die Verjährungsfrist für gesetzliche Mängelansprüche beträgt zwei
          Jahre und beginnt mit der Ablieferung der Ware.
        </p>
        <p>
          ​(2) Etwaige von uns gegebene Verkäufergarantien für bestimmte Artikel
          oder von den Herstellern bestimmter Artikel eingeräumte
          Herstellergarantien treten neben die Ansprüche wegen Sach- oder
          Rechtsmängeln im Sinne von Abs. 1. Einzelheiten des Umfangs solcher
          Garantien ergeben sich aus den Garantiebedingungen, die den Artikeln
          gegebenenfalls beiliegen.​
        </p>
        <p className="font-bold">§ 10 Haftung</p>
        <p></p>
        <p>
          (1) Wir haften Ihnen gegenüber in allen Fällen vertraglicher und
          außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit nach
          Maßgabe der gesetzlichen Bestimmungen auf Schadens-ersatz oder Ersatz
          vergeblicher Aufwendungen.
        </p>
        ​
        <p>
          (2) In sonstigen Fällen haften wir – soweit in Abs. 3 nicht abweichend
          geregelt – nur bei Verletzung einer Vertragspflicht, deren Erfüllung
          die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht
          und auf deren Einhaltung Sie als Kunde regelmäßig vertrauen dürfen (so
          genannte Kardinalpflicht), und zwar beschränkt auf den Ersatz des
          vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist
          unsere Haftung vorbehaltlich der Regelung in Abs. 3 ausgeschlossen.
        </p>
        ​
        <p>
          (3) Unsere Haftung für Schäden aus der Verletzung des Lebens, des
          Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt
          von den vorstehenden Haftungsbeschränkungen und ausschlüssen
          unberührt.​
        </p>
        <p className="font-bold">§ 11 Urheberrechte</p>
        <p>
          Wir haben an allen Bildern, Filme und Texten, die in unserem
          Online-Shop veröffentlicht werden, Urheberrechte. Eine Verwendung der
          Bilder, Filme und Texte ist ohne unsere ausdrückliche Zustimmung nicht
          gestattet.
        </p>
        <p className="font-bold">§ 12 Außergerichtliche Streitbeilegung</p>
        <p>
          Bei Meinungsverschiedenheiten stehen Verbrauchern verschiedene
          außergerichtliche Streitbeilegungsstellen zur Verfügung. Die
          europäische Union hat eine Online-Streitbeilegungsplattform unter dem
          Link
        </p>
        <p>
          https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE
        </p>
        <p>eingerichtet.</p>
        <p>
          Wir beabsichtigen, etwaige Meinungsverschiedenheiten oder
          Unstimmigkeiten direkt mit Ihnen zu klären. Im Übrigen sind wir auch
          nicht zur Teilnahme an einem Streitbeilegungsverfahren verpflichtet.
          Daher nehmen wir nicht an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teil.
        </p>
        <p>
          Sollten Meinungsverschiedenheiten oder Unstimmigkeiten auftreten,
          wenden Sie sich bitte direkt an uns.
        </p>
        <p className="font-bold">Anlage 1</p>
        <p>Muster-Widerrufsformular</p>
        <p>
          (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
          Formular aus und senden Sie es zurück.)
        </p>
        <p>An</p>
        <p>
          An den Pappeln 4a
          <br />
          21521 Wohltorf
        </p>
        <p>Telefax: +49 (0)410 4962 1236; E-Mail: info@sojuhalle.com</p>
        <p>
          Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
          Vertrag über den Kauf der folgenden Waren
        </p>
        ​<p>- bestellt am (*)/erhalten am (*)</p>
        <p>- Name des/der Verbraucher(s)</p>
        <p>- Anschrift des/der Verbraucher(s)</p>
        <p>
          - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        </p>
        <p>- Datum</p>
        <p>----------------</p>
        <p>(*) Unzutreffendes streichen.</p>​
      </div>
    </div>
  );
};

export default AGBView;
