import { useEffect, useRef, useState } from "react";

import CartBadge from "assets/icons/CartBadge";
import { Modal } from "@mui/material";
import { ProductContents } from "assets/Content";
//import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
//import Badge from "@mui/material/Badge";
import { getShipping } from "utils/helper";
import { getShippingInfo } from "firestore/shipping";
import { store } from "../../store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type State = { id: string; qty: number };
export const Cart = (props: any) => {
  //const [props.showCart, props.setShowCart] = useState(false);
  const [preorderModalOpen, setPreorderModalOpen] = useState(false);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [shippingInfo, setShippingInfo] = useState([]);
  const navigate = useNavigate();
  const items: State[] = useSelector((state: any) => state.cart);

  const checkPreorder = (items: State[]) =>
    !items
      .map(
        (item) =>
          ProductContents.find(({ id }) => id.toString() == item.id)?.available
      )
      .every((avail) => avail == true);
  //console.log(window.localStorage.getItem("cart-items"));

  const addOne = (product: string) => {
    store.dispatch({ type: "cart/addOne", payload: product });
  };
  const substractOne = (product: string) => {
    store.dispatch({ type: "cart/substractOne", payload: product });
  };

  const removeProduct = (product: string) => {
    store.dispatch({ type: "cart/removeProduct", payload: product });
  };

  const subtotal =
    Math.round(
      items.reduce((accum, current) => {
        const unitPrice = ProductContents.find(
          (product) => product.id.toString() == current?.id
        )?.price;
        if (unitPrice) return unitPrice * current.qty + accum;
        else return accum;
      }, 0) * 100
    ) / 100;

  //const tax = Math.round(Number((subtotal * 0.19 * 100) / 1.19)) / 100;
  const shipping = getShipping(items);
  const total = Math.round(Number(shipping + subtotal) * 100) / 100;

  const totalQty = items.reduce((accum, current) => current.qty + accum, 0);
  const handleCheckout = (items: State[]) => {
    if (checkPreorder(items)) setPreorderModalOpen(true);
    else {
      navigate("/cart");
      props.setShowCart(false);
    }
  };

  useEffect(() => {
    getShippingInfo().then((res: any) => {
      setShippingInfo(res);
    });
  }, []);

  return (
    <div className="fixed right-0 z-40">
      <Modal
        open={preorderModalOpen}
        onClose={() => setPreorderModalOpen(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-5/6 max-w-lg m-auto bg-white rounded-lg md:w-1/2 h-60"
      >
        <div className="w-full bg-white rounded-lg h-fit">
          <div className="mx-auto text-center h-fit">
            <h3 className="inline-block w-full mt-8 text-3xl text-center md:text-4xl font-akrobat">
              Order includes pre-order
            </h3>
            <p className="w-5/6 mx-auto mt-4 text-left text-md md:text-lg">
              Your order includes pre-order.
            </p>
            <p className="w-5/6 mx-auto mb-2 text-left text-md md:text-lg">
              These products will be shipped when they become available.
            </p>
            <p className="w-5/6 mx-auto my-2 text-center text-md md:text-lg">
              Would you like to proceed?
            </p>
            <div className="inline-flex justify-center w-32 my-8">
              <button
                onClick={() => {
                  navigate("/cart");
                  props.setShowCart(false);
                  setPreorderModalOpen(false);
                }}
                className="w-full px-6 py-2 m-auto mx-2 text-white bg-black rounded-lg"
              >
                Yes
              </button>
              <button
                onClick={() => setPreorderModalOpen(false)}
                className="w-full px-6 py-2 m-auto mx-2 text-white bg-black rounded-lg"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={shippingModalOpen}
        onClose={() => setShippingModalOpen(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-full max-w-lg m-auto bg-white rounded-lg max-w-screen sm:w-5/6 lg:w-1/2 h-fit"
      >
        <div className="w-full max-h-screen bg-white rounded-lg max-w-screen h-fit">
          <div className="mx-auto text-center h-fit">
            <h3 className="inline-block w-full my-2 text-xl text-center sm:my-8 sm:text-3xl md:text-4xl font-akrobat h-fit">
              Shipping Costs
            </h3>
            <p className="w-5/6 mx-auto mb-2 text-sm text-left h-fit sm:text-md md:text-lg">
              See below for shipping cost for available countries:
            </p>
            <ul className="w-11/12 mx-auto sm:w-3/4">
              {shippingInfo
                .sort((a: any, b: any) => (a.country > b.country ? 1 : -1))
                .map((shipping: any) => (
                  <li
                    key={shipping.country}
                    className="inline-flex w-full text-sm text-left sm:text-md"
                  >
                    <span className="w-[8rem]">{shipping.country}:</span>
                    <span className="w-[3rem]">
                      {`\u20AC${shipping.shippingCost.toFixed(2)}`}
                    </span>
                    {shipping?.minFreeShipping && (
                      <span className="ml-1 text-xs sm:text-sm w-fit">
                        (free shipping over {"\u20AC"}
                        {shipping.minFreeShipping})
                      </span>
                    )}
                  </li>
                ))}
            </ul>
            <div className="inline-flex justify-center w-32 my-8">
              <button
                onClick={() => setShippingModalOpen(false)}
                className="w-full px-6 py-2 m-auto mx-2 text-white bg-black rounded-lg"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="float-right h-full bg-white">
        <nav
          //ref={cartRef}
          className={`h-full max-h-[90vh] bg-white fixed mt-28 md:mt-[120px] mt-border min-w-fit xl:w-1/3 lg:w-1/2 md:w-2/3 w-full ease-in-out duration-500 max-w-screen ${
            props.showCart ? "-translate-x-full" : "translate-x-full"
          }`}
          translate="yes"
        >
          <div className="flex w-full">
            <h3 className="justify-center w-full m-auto mt-4 text-lg sm:mt-12 sm:text-3xl font-akrobat">
              CART
            </h3>
            <div className="absolute w-full text-right">
              <button
                className="m-3 text-xl sm:m-8 text-stone-700"
                onClick={() => props.setShowCart(false)}
              >
                X
              </button>
            </div>
          </div>
          <div className="w-full h-full mx-auto">
            <div className="flex overflow-y-scroll h-1/2 max-h-[550px]">
              <ul className="w-full h-full grid-flow-row">
                {subtotal > 0 ? (
                  <li className="w-11/12 mx-auto border-b border-black h-fit">
                    <div className="flex justify-center w-full my-4 text-xs sm:text-lg">
                      <span className="w-20 mx-4 sm:w-40">Product</span>
                      <span className="w-12 mx-2 sm:w-24">Quantity</span>
                      <span className="w-12 mx-2 sm:w-24">Unit Price</span>
                      <span className="w-12 mx-2 sm:w-24">Price</span>
                      <span className="w-2 h-4 sm:w-4"></span>
                    </div>
                  </li>
                ) : (
                  <div className="flex w-full h-full">
                    <span className="justify-center m-auto text-xl sm:text-2xl">
                      Cart is empty.
                    </span>
                  </div>
                )}
                {items.map(({ id, qty }) => {
                  const product = ProductContents.find(
                    (product) => product.id.toString() == id
                  );
                  return (
                    product && (
                      <li className="flex w-full h-fit">
                        <div className="flex justify-center w-11/12 mx-auto my-4 text-xs sm:text-lg">
                          <span className="w-20 mx-4 my-auto text-left sm:w-40">
                            {product.name} ({product.nameKor})
                          </span>
                          <div className="flex justify-center w-12 mx-2 sm:w-24 h-fit">
                            <button
                              className="w-6 m-auto text-xl text-black rounded-md"
                              onClick={() => substractOne(id)}
                            >
                              -
                            </button>
                            <span className="flex mx-2 my-auto">{qty}</span>
                            <button
                              className="w-6 m-auto text-xl text-black rounded-md"
                              onClick={() => addOne(id)}
                            >
                              +
                            </button>
                          </div>
                          <span className="justify-center w-12 mx-2 my-auto sm:w-24">
                            {product.price + "\u20AC"}
                          </span>
                          <span className="justify-center w-12 mx-2 my-auto sm:w-24">
                            {(
                              Math.round(product.price * qty * 100) / 100
                            ).toFixed(2) + "\u20AC"}
                          </span>
                          <button
                            className="w-2 h-4 my-auto sm:my-0 sm:w-4"
                            onClick={() => {
                              removeProduct(id);
                            }}
                          >
                            <span className="w-full h-full my-auto">X</span>
                          </button>
                        </div>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            {subtotal > 0 && (
              <div className="w-11/12 mx-auto min-h-[10vh] overflow-y-scroll border-black border-t mt-4 block relative">
                <div className="block w-1/2 h-full mx-auto mt-4 text-sm sm:text-lg">
                  <div className="inline-flex justify-around float-left w-full ">
                    <span className="float-left w-1/2 text-left">
                      Subtotal:
                    </span>
                    <span className="float-right w-1/2 text-right">
                      {subtotal.toFixed(2) + "\u20AC"}
                    </span>
                  </div>
                  <div className="inline-flex justify-around float-left w-full ">
                    <span className="float-left w-1/2 text-left">
                      Shipping:
                    </span>
                    <span className="w-1/2 my-auto text-sm text-right">
                      <button
                        className="text-blue-500"
                        onClick={async () => {
                          setShippingModalOpen(true);
                        }}
                      >
                        See details for shipping
                      </button>
                    </span>
                  </div>
                  <div className="inline-flex justify-around float-left w-full mt-1 border-t border-black">
                    <span className="float-left w-1/2 text-lg text-left">
                      Total:
                    </span>
                    <span className="float-right w-1/2 text-lg font-semibold text-right">
                      {total.toFixed(2) + "\u20AC"}
                    </span>
                  </div>
                  <div className="inline-flex justify-around float-left w-full">
                    <span className="float-left w-full text-sm text-left">
                      Tax (VAT 19%) Included
                    </span>
                  </div>
                </div>
                <div className="flex w-full">
                  <button
                    className="w-1/2 max-w-[300px] px-4 py-2 m-4 mx-auto text-white rounded-3xl bg-tertiary hover:bg-tertiaryhover"
                    onClick={() => handleCheckout(items)}
                  >
                    Check Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
      <button
        className="z-50 float-right mt-16 mr-6 text-stone-700"
        onClick={() => props.setShowCart(!props.showCart)}
      >
        <CartBadge itemNumber={totalQty} />
      </button>
    </div>
  );
};
export default Cart;
