import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MenuItem, Radio, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import { createPurchase } from "firestore/purchases";
import { createTransfer } from "firestore/transfers";
import { getAllLocations } from "firestore/locations";
import { getAllProducts } from "firestore/products";

export const Inventories = () => {
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [allLocations, setAllLocations] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isPurhcaseType, setIsPurchaseType] = useState<boolean>(false);
  const [created, setCreated] = useState<any>();
  const [fromLoc, setFromLoc] = useState<string>();
  const [toLoc, setToLoc] = useState<string>();
  const [note, setNote] = useState<string>("");

  useEffect(() => {
    getAllProducts().then((res) => {
      setAllProducts(res);
    });
    getAllLocations().then((res) => {
      setAllLocations(res);
    });
  }, []);

  const columns: GridColDef[] = [
    { field: "productName", headerName: "Product", minWidth: 300 },
    { field: "inStock", headerName: "Total in Stock", minWidth: 100 },
    { field: "inStockHamburg", headerName: "Stock (Hamburg)", minWidth: 100 },
    { field: "inStockBerlin", headerName: "Stock (Berlin)", minWidth: 100 },
    { field: "inStockHanover", headerName: "Stock (Hanover)", minWidth: 100 },
    {
      field: "businessPrice",
      headerName: "B2B Price",
      minWidth: 100,
    },
    {
      field: "consumerPrice",
      headerName: "B2C Price",
      minWidth: 100,
    },
  ];
  const rows = allProducts.map((product) => ({
    id: product.id,
    productName: `${product.name} (${product.nameKor})`,
    inStock: product?.inStock?.total ?? "NA",
    inStockHamburg: product?.inStock?.hamburg ?? "NA",
    inStockBerlin: product?.inStock?.berlin ?? "NA",
    inStockHanover: product?.inStock?.hanover ?? "NA",
    businessPrice: product?.businessPrice
      ? `\u20AC${product.businessPrice.toFixed(2)}`
      : "NA",
    consumerPrice: product?.consumerPrice
      ? `\u20AC${product.consumerPrice.toFixed(2)}`
      : "NA",
  }));

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Inventory</h3>
        <div className="flex w-full h-full mx-12">
          <Accordion className="w-11/12 h-full">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <div className="text-seconadry">Create Purchase / Transfer</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full h-full pt-4 border-t-2">
                <div className="flex-row w-full h-full">
                  <div className="flex-row w-full mx-2 h-fit">
                    <div className="inline-flex w-full">
                      <div className="flex w-1/2">
                        <span className="my-auto mr-2 w-[4rem] text-left">
                          Type
                        </span>
                        <div className="flex">
                          <Radio
                            checked={!isPurhcaseType}
                            onChange={() => setIsPurchaseType(!isPurhcaseType)}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                          <span className="my-auto mr-6">Transfer</span>
                          <Radio
                            checked={isPurhcaseType}
                            onChange={() => setIsPurchaseType(!isPurhcaseType)}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                          <span className="my-auto">Purchase</span>
                        </div>
                      </div>
                      <div className="flex w-1/2">
                        <span className="my-auto mr-2 w-[4rem] text-left">
                          Date
                        </span>
                        <DatePicker
                          selected={created}
                          onChange={(date: Date) => {
                            setCreated(date);
                          }}
                          className="flex px-2 mt-2 border-2 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="flex w-full my-1">
                      <div className="flex-row w-full">
                        <span className="flex w-full my-auto text-left">
                          Product
                        </span>
                        {Array.from(
                          Array(selectedProducts.length + 1).keys()
                        ).map((index) => (
                          <div className="inline-flex w-full">
                            <span className="my-auto mr-2 w-[4rem] text-left text-md">
                              Item {index}
                            </span>
                            <Select
                              label="Product"
                              className="w-[20rem] h-[2.5rem] my-auto"
                              key={index}
                              //defaultValue={""}
                              onChange={(e) => {
                                if (selectedProducts.includes(e.target.value)) {
                                  alert("Duplicate Product");
                                } else {
                                  if (index == selectedProducts.length)
                                    selectedProducts.push(e.target.value);
                                  else selectedProducts[index] = e.target.value;
                                  const businessPrice =
                                    allProducts.find(
                                      ({ name }) =>
                                        name == (e.target.value as any)?.name
                                    )?.businessPrice ?? "";
                                  selectedProducts[index].qty = 1;
                                  selectedProducts[index].businessPrice =
                                    businessPrice;
                                  setSelectedProducts([...selectedProducts]);
                                }
                              }}
                            >
                              {allProducts.map((product) => (
                                <MenuItem value={product}>
                                  {product?.name} ({product?.nameKor})
                                </MenuItem>
                              ))}
                            </Select>
                            {selectedProducts[index] && (
                              <div className="flex w-fit">
                                <div className="inline-flex w-[10rem] m-auto ml-2 border h-[2rem]">
                                  <button
                                    className="w-4 m-auto text-lg text-black rounded-md"
                                    onClick={() => {
                                      if (selectedProducts[index].qty) {
                                        selectedProducts[index].qty -= 1;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <button
                                    onClick={() => {
                                      selectedProducts[index].qty = "";
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                  >
                                    <TextField
                                      type="number"
                                      className="w-full text-center max-w-[8vh] max-h-fit"
                                      size="small"
                                      value={selectedProducts[index]?.qty ?? ""}
                                      onChange={(e: any) => {
                                        selectedProducts[index].qty = Number(
                                          e.target.value
                                        );
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }}
                                      sx={{
                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button":
                                          {
                                            "-webkit-appearance": "none",
                                            margin: "0",
                                          },
                                        "input[type=number]": {
                                          "-moz-appearance": "textfield",
                                        },
                                        "& fieldset": { border: "none" },
                                      }}
                                      inputProps={{
                                        style: {
                                          textAlign: "center",
                                          padding: "4px",
                                        },
                                      }}
                                    />
                                  </button>
                                  <button
                                    className="w-6 m-auto text-xl text-black rounded-md"
                                    onClick={() => {
                                      selectedProducts[index].qty += 1;
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                                {isPurhcaseType && (
                                  <div>
                                    <div className="min-w-[6rem] inline-flex w-42 m-auto ml-2 border h-[2rem]">
                                      <button
                                        onClick={() => {
                                          selectedProducts[
                                            index
                                          ].businessPrice = "";
                                          setSelectedProducts([
                                            ...selectedProducts,
                                          ]);
                                        }}
                                      >
                                        <span className="mx-1 my-auto">
                                          {"\u20AC"}
                                        </span>
                                        <TextField
                                          type="number"
                                          className="w-48 text-center max-w-[10vh] max-h-fit"
                                          size="small"
                                          value={
                                            selectedProducts[index]
                                              ?.businessPrice ?? ""
                                          }
                                          onChange={(e: any) => {
                                            selectedProducts[
                                              index
                                            ].businessPrice = Number(
                                              Number(e.target.value).toFixed(2)
                                            );
                                            setSelectedProducts([
                                              ...selectedProducts,
                                            ]);
                                          }}
                                          sx={{
                                            "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button":
                                              {
                                                "-webkit-appearance": "none",
                                                margin: "0",
                                              },
                                            "input[type=number]": {
                                              "-moz-appearance": "textfield",
                                            },
                                            "& fieldset": { border: "none" },
                                          }}
                                          inputProps={{
                                            style: {
                                              textAlign: "center",
                                              padding: "4px",
                                            },
                                          }}
                                        />
                                      </button>
                                    </div>

                                    <span className="my-auto ml-4 text-lg">
                                      {"\u20AC"}
                                      {Number(
                                        selectedProducts[index].qty *
                                          selectedProducts[index].businessPrice
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                        {isPurhcaseType && (
                          <div className="flex justify-end">
                            <div className="absolute inline-flex mr-12">
                              <span className="w-24 mr-4">Total Amount</span>
                              <span className="w-12">
                                {"\u20AC" +
                                  selectedProducts
                                    .reduce(
                                      (prev, curr) =>
                                        curr?.businessPrice * curr?.qty + prev,
                                      //Number(shippingCost) - Number(discount)
                                      0
                                    )
                                    //* 1.19
                                    .toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="inline-flex w-full my-1 mt-4">
                      <span className="w-[4rem] mr-2 my-auto text-left">
                        From
                      </span>
                      <Select
                        className="w-1/2 h-[2rem]"
                        label="From"
                        onChange={(e: any) => setFromLoc(e.target.value)}
                        placeholder="From"
                      >
                        {allLocations
                          .filter(({ isVendor }) =>
                            isPurhcaseType ? isVendor : !isVendor
                          )
                          .map((location) => (
                            <MenuItem value={location}>
                              {location?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-[4rem] mr-2 my-auto text-left">
                        To
                      </span>
                      <Select
                        className="w-1/2 h-[2rem]"
                        label="To"
                        onChange={(e: any) => setToLoc(e.target.value)}
                        placeholder="To"
                      >
                        {allLocations
                          .filter(({ isVendor }) => !isVendor)
                          .map((location) => (
                            <MenuItem value={location}>
                              {location?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-[4rem] mr-2 my-auto text-left">
                        Note (optional)
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={() => {
                    const newEntry = {
                      created,
                      fromLoc,
                      toLoc,
                      note,
                      items: selectedProducts,
                    };
                    (isPurhcaseType
                      ? createPurchase(newEntry)
                      : createTransfer(newEntry)
                    ).then(() => {
                      getAllProducts().then((res) => {
                        setAllProducts([...res]);
                        alert("Inventory Updated");
                        setFromLoc("");
                        setCreated(null);
                        setSelectedProducts([]);
                        setIsPurchaseType(false);
                        setToLoc("");
                        setNote("");
                      });
                    });
                  }}
                >
                  {isPurhcaseType ? "Purchase" : "Transfer"}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="w-full h-full mt-4 xl:w-11/12 xl:mx-12">
          <DataGrid
            rows={rows}
            columns={columns}
            rowSelection={false}
            pageSizeOptions={[5, 10, 25, 50]}
            sortingOrder={["desc"]}
            sortModel={[{ field: "id", sort: "desc" }]}
          />
        </div>
      </div>
    </div>
  );
};

export default Inventories;
