import { getShipping, getSubtotal, getTax, getTotal } from "utils/helper";

import { Buffer } from "buffer";
import { ProductContents } from "assets/Content";
import { sendOrderConfirmEmail } from "emailjs/emailjs";

const clientIdAndSecret = `${process.env.REACT_APP_PAYPAL_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SECRET}`;
const base64 = Buffer.from(clientIdAndSecret).toString("base64");
const baseUrl = process.env.REACT_APP_PAYPAL_BASE_URL;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Basic ${base64}`,
};
type State = { id: string; qty: number };

export const createOrder = async (
  address: any,
  items: State[],
  email: string,
  phoneNumber: string,
  country: any
) => {
  localStorage.setItem("cart-items", JSON.stringify(items));
  return fetch(`${baseUrl}/checkout/orders`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      intent: "CAPTURE",
      purchase_units: [
        {
          custom_id: `${email}&${phoneNumber}`,
          amount: {
            breakdown: {
              item_total: {
                currency_code: "EUR",
                value: (getSubtotal(items) - getTax(items)).toFixed(2),
              },
              shipping: {
                currency_code: "EUR",
                value: getShipping(items, country).toFixed(2),
              },
              tax_total: {
                currency_code: "EUR",
                value: getTax(items).toFixed(2),
              },
            },
            value: getTotal(items, country).toFixed(2),
            currency_code: "EUR",
          },
          shipping: {
            address,
          },
          items: items.map((item) => {
            const product = ProductContents.find(
              ({ id }) => id.toString() == item?.id
            );
            if (product?.name && product?.price)
              return {
                name: product.name,
                quantity: item.qty,
                unit_amount: {
                  currency_code: "EUR",
                  value: (product.price / 1.19).toFixed(2),
                },
                tax: {
                  currency_code: "EUR",
                  value: (
                    Math.round((Number(product.price) * 0.19 * 100) / 1.19) /
                    100
                  ).toFixed(2),
                },
              };
          }),
        },
      ],
      payment_source: {
        paypal: {
          experience_context: {
            payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
            brand_name: "SOJUHALLE",
            locale: "en-US", // Change
            landing_page: "LOGIN",
            shipping_preference: "SET_PROVIDED_ADDRESS",
            user_action: "PAY_NOW",
            return_url: `${process.env.REACT_APP_BASE_URL}/cart`,
            cancel_url: `${process.env.REACT_APP_BASE_URL}/cart`,
            //return_url: "https://sojuhalle.web.app/cart",
            //cancel_url: "https://sojuhalle.web.app/cart",
          },
        },
      },
    }),
  });
};

export const getOrder = async (token: string) => {
  return fetch(`${baseUrl}/checkout/orders/${token}`, {
    method: "GET",
    headers,
  });
};

export const completeOrder = async (token: string) => {
  const order = await getOrder(token)
    .then(async (res: any) => res.json())
    .catch((err) => console.error(err));
  if (order.status === "APPROVED") {
    return await fetch(`${baseUrl}/checkout/orders/${token}/capture`, {
      method: "POST",
      headers,
    })
      .then(async () => {
        const completedOrder = await getOrder(token)
          .then(async (res: any) => res.json())
          .catch((err) => console.error(err));
        await sendOrderConfirmEmail(completedOrder);
        return completedOrder;
      })
      .catch((err) => console.error(err));
  } else return order;
};

export const getUnitPrice = (productName: string) =>
  Number(
    ProductContents.find(({ name }) => name == productName)?.price
  ).toFixed(2);

export const getSubtotalPrice = (productName: string, qty: number) => {
  const product = ProductContents.find(({ name }) => name == productName);
  if (product && product?.price) return Number(product.price * qty).toFixed(2);
};
