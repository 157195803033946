import { useEffect, useState } from "react";

//import { fetchAllPosts } from "instagram/instagram";

type PostContents = {
  caption: string;
  id: string;
  media_url: string;
  permalink: string;
};

const instagramLastUpdated = "InstagramLastUpdated";

export const BlogView = () => {
  const [postContents, setPostContents] = useState<PostContents[]>([]);

  //useEffect(() => {
  //  if (postContents.length === 0)
  //    fetchAllPosts().then((res: PostContents[]) => {
  //      setPostContents(res);
  //      console.log(res);
  //    });
  //}, []);

  return (
    <div>
      <div className="relative flex w-full h-full min-h-screen">
        <div className="w-full mx-auto mt-48 lg:w-3/4 mb-72">
          <ul className="mx-8">
            {postContents &&
              postContents.map(({ id, caption, media_url, permalink }) => {
                return (
                  <li key={id} className="block w-full mb-20 md:flex">
                    <div className="block w-full mx-auto md:w-1/2">
                      <a href={permalink} target="_blank">
                        <img
                          className="block w-full max-w-md mx-auto rounded-lg"
                          src={media_url}
                        />
                      </a>
                    </div>
                    <div className="flex w-full h-full">
                      <span className="w-full h-full px-8 py-2 my-8 text-sm text-left md:my-0 md:text-md lg:text-lg font-akrobat">
                        {caption}
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogView;
function updateContents() {
  throw new Error("Function not implemented.");
}
