import { getSubtotalPrice, getUnitPrice } from "paypal/paypal";

export const OrderDetails = ({ order }: any) => {
  const { shipping, items, amount, custom_id } = order?.purchase_units[0];
  const email = custom_id.split("&")[0];
  const phoneNumber = custom_id.split("&")[1];
  const total = amount?.value;
  const shippingCost = amount?.breakdown?.shipping?.value;
  const tax = amount?.breakdown?.tax_total?.value;
  const subtotal = (
    Number(amount?.breakdown?.item_total?.value) + Number(tax)
  ).toFixed(2);
  const { address, name } = shipping;
  const {
    address_line_1,
    address_line_2,
    admin_area_1,
    admin_area_2,
    postal_code,
    country_code,
  } = address;
  //const { payer } = order;

  return (
    <div className="w-full h-full max-w-[80vw] mx-auto">
      <div className="flex">
        <div className="w-1/2 h-full text-left">
          <span className="block w-full">Order Date: </span>
          <span className="block w-full">
            {order?.create_time?.split("T")[0]}
          </span>
          <span className="block w-full">{email}</span>
          <span className="block w-full">{phoneNumber}</span>
        </div>
        <div className="w-1/2 h-full text-right">
          <span className="">Order Number: </span>
          <span className="">{order?.id}</span>
        </div>
      </div>
      <div className="flex mt-8">
        <div className="w-1/2 h-full text-left">
          <span className="block w-full font-semibold">Shipping Address</span>
          <span className="block w-full">{name?.full_name}</span>
          <span className="block w-full">{address_line_1}</span>
          <span className="block w-full">{address_line_2}</span>
          <span className="block w-full">
            {admin_area_2}, {admin_area_1} {postal_code}
          </span>
          <span className="flex w-full">{country_code}</span>
        </div>
        <div className="w-1/2 h-full text-right">
          <span className="block w-full font-semibold">Order Summary</span>
          <span className="block w-full">
            Item(s) Subtotal: {subtotal + "\u20AC"}
          </span>
          <span className="block w-full">
            Shipping: {shippingCost + "\u20AC"}
          </span>
          {/*<span className="block w-full">
            Tax (VAT 19%) Included: {tax} "\u20AC"
          </span>*/}
          <span className="block w-full font-semibold">
            Total: {total + "\u20AC"}
          </span>
          <span className="block w-full text-sm">Tax (VAT 19%) Included</span>
        </div>
      </div>
      <ul className="block w-full h-full my-12">
        <li className="grid w-full h-full grid-cols-4 py-2 my-4 border-b-2 border-spacing-4">
          <span className="col-span-1">Product</span>
          <span className="col-span-1">Quantity</span>
          <span className="col-span-1">Unit Price</span>
          <span className="col-span-1">Price</span>
        </li>
        {items &&
          items.map((item: any) => (
            <li className="grid w-full h-full grid-cols-4 my-4">
              <span className="col-span-1">{item.name}</span>
              <span className="col-span-1">{item.quantity}</span>
              <span className="col-span-1">
                {getUnitPrice(item.name) + "\u20AC"}
              </span>
              <span className="col-span-1">
                {getSubtotalPrice(item.name, item.quantity) + "\u20AC"}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default OrderDetails;
