import "leaflet/dist/leaflet.css";

import { Container, Modal } from "@mui/material";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useEffect, useRef, useState } from "react";

import emailjs from "emailjs-com";
import { icon } from "leaflet";

export const ContactView = () => {
  const form = useRef<HTMLFormElement>(null);
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (
      fromName.length > 0 &&
      fromEmail.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [fromName, fromEmail, subject, message]);

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (canSubmit && form.current) {
      emailjs
        .sendForm(
          "sojuhalle_info",
          "template_sojuhalle",
          form.current,
          "VrtwxuHHFbnrp3Gqn"
        )
        .then(
          () => {
            setModalMessage("Email sent! Thank you!");
            setOpen(true);
            form?.current?.reset();
            setFromName("");
            setFromEmail("");
            setSubject("");
            setMessage("");
            setCanSubmit(false);
          },
          (err) => {
            console.error(err);
            setOpen(true);
            setModalMessage(
              "Something went wrong.\n Please send me an email directly at info@sojuhalle.com"
            );
          }
        );
    } else {
      setOpen(true);
      setModalMessage("Please fill all the fields.");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-1/2 max-w-lg m-auto bg-white rounded-lg h-60"
      >
        <div className="w-full h-full bg-white rounded-lg">
          <div className="flex justify-end w-full">
            <button
              onClick={() => setOpen(false)}
              className="w-12 h-2 my-2 text-black rounded-lg"
            >
              X
            </button>
          </div>
          <div className="mx-auto text-center">
            <h3 className="inline-block w-full mt-8 text-4xl text-center font-akrobat">
              SOJUHALLE
            </h3>
            <p className="justify-center inline-block w-5/6 mx-auto my-4 text-lg text-center">
              {modalMessage}
            </p>
          </div>
        </div>
      </Modal>
      <div className="relative flex w-full h-full min-h-screen mx-auto">
        <div className="w-full h-full mx-auto mt-36 md:w-3/4 lg:w-2/3">
          <h3 className="w-full my-8 text-2xl font-semibold">Contact Us</h3>
          <div className="grid w-full grid-rows-2 mb-32 lg:flex">
            <div className="w-full h-full mx-auto">
              <form ref={form} onSubmit={sendEmail} className="mb-12 mx-14">
                <input
                  type="text"
                  name="from_name"
                  onChange={(e) => setFromName(e.target.value)}
                  placeholder="Name"
                  className="flex w-full px-3 py-2 my-4 rounded-lg"
                />
                <input
                  type="email"
                  name="from_email"
                  onChange={(e) => setFromEmail(e.target.value)}
                  placeholder="Email"
                  className="flex w-full px-3 py-2 my-4 rounded-lg"
                />
                <input
                  type="subject"
                  name="subject"
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Subject"
                  className="flex w-full px-3 py-2 my-4 rounded-lg"
                />
                <textarea
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                  className="flex w-full h-64 px-3 py-2 my-4 rounded-lg"
                />
                <button
                  type="submit"
                  className="flex float-right w-32 px-4 py-2 mx-auto mt-4 font-semibold text-white rounded-2xl bg-tertiary"
                >
                  <span className="mx-auto p-auto">Send</span>
                </button>
              </form>
            </div>
            <div className="block w-3/4 h-full mx-auto lg:mb-0">
              <div className="flex w-full h-full mx-auto mt-4 rounded-lg lg:block">
                <MapContainer
                  center={[53.52, 10.28]}
                  zoom={13}
                  scrollWheelZoom={true}
                  style={{
                    width: "75%",
                    minWidth: "30vw",
                    height: "75%", //350px
                    minHeight: "30vw",
                    margin: "auto",
                    borderRadius: "15px",
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    position={[53.52, 10.28]}
                    icon={icon({
                      iconUrl: "./marker-icon.png",
                    })}
                  >
                    <Popup>
                      <a
                        target="_blank"
                        href="https://www.google.com/search?q=An+Den+Pappeln+4a+NA%2C+Wohltorf%2C+21521+Germany&sca_esv=de247fdd6a28f4c9&sca_upv=1&tbm=lcl&sxsrf=AM9HkKnyNq1fMI2sgdufA1-vGtgjEOaQCQ%3A1704333157185&ei=ZQ-WZd78CpOLwbkPjfK7qAI&ved=0ahUKEwiew6bjz8KDAxWTRTABHQ35DiUQ4dUDCAk&uact=5&oq=An+Den+Pappeln+4a+NA%2C+Wohltorf%2C+21521+Germany&gs_lp=Eg1nd3Mtd2l6LWxvY2FsIi1BbiBEZW4gUGFwcGVsbiA0YSBOQSwgV29obHRvcmYsIDIxNTIxIEdlcm1hbnkyBBAjGCdI_AJQAFgAcAB4AJABAJgBhAGgAYQBqgEDMC4xuAEDyAEAiAYB&sclient=gws-wiz-local"
                      >
                        <span className="font-semibold text-center">
                          Soju Halle GmbH <br />
                          An den Pappeln 4a 21521 Wohltorf
                        </span>
                      </a>
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
              <div className="block w-full m-auto text-center lg:mt-4">
                <span className="font-semibold text-center">
                  Soju Halle GmbH <br />
                  An den Pappeln 4a 21521 Wohltorf
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactView;
