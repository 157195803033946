export const ZahlungsmethodeView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Zahlungsmethode</h3>
        <p>
          Datenschutzbestimmungen für die Nutzung von PayPal als
          Zahlungsmethode:
          <br />
          Wir integrieren auf dieser Website Komponenten von PayPal, einem
          Online-Zahlungsdienstleister. Über PayPal-Konten, die virtuelle
          Privat- oder Geschäftskonten sind, werden Zahlungen abgewickelt.
          Alternativ können auch virtuelle Zahlungen über Kreditkarten getätigt
          werden. Ein PayPal-Konto wird über eine E-Mail-Adresse geführt,
          wodurch eine klassische Kontonummer entfällt. Mit PayPal können
          Online-Zahlungen an Dritte gesendet oder empfangen werden. Zudem
          bietet PayPal Treuhänderdienste und Käuferschutz an.
          <br />
          Die europäische Betreibergesellschaft von PayPal ist PayPal (Europe)
          S.à.r.l. & Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg,
          Luxemburg.
          <br />
          Wenn die betroffene Person während des Bestellvorgangs in unserem
          Online-Shop „PayPal“ als Zahlungsmethode wählt, werden automatisch
          Daten an PayPal übermittelt. Mit dieser Auswahl willigt die betroffene
          Person in die erforderliche Datenübermittlung für die
          Zahlungsabwicklung ein.
          <br />
          Üblicherweise umfassen die übermittelten Daten Vorname, Nachname,
          Adresse, E-Mail-Adresse, IP-Adresse, Telefonnummer, Mobiltelefonnummer
          oder andere für die Zahlungsabwicklung relevante Daten. Zur Abwicklung
          des Kaufvertrages werden auch Daten im Zusammenhang mit der Bestellung
          übermittelt.
          <br />
          Die Datenübermittlung dient der Zahlungsabwicklung und der
          Betrugsprävention. Der Verantwortliche übermittelt personenbezogene
          Daten an PayPal, wenn ein berechtigtes Interesse besteht. PayPal kann
          die Daten gegebenenfalls an Wirtschaftsauskunfteien weiterleiten, um
          Identitäts- und Bonitätsprüfungen durchzuführen.
          <br />
          PayPal kann personenbezogene Daten an verbundene Unternehmen und
          Dienstleister weitergeben, um vertragliche Verpflichtungen zu erfüllen
          oder die Daten im Auftrag zu verarbeiten.
          <br />
          Die betroffene Person kann die Einwilligung zur Verarbeitung
          personenbezogener Daten jederzeit gegenüber PayPal widerrufen. Ein
          Widerruf betrifft nicht die zur Zahlungsabwicklung erforderlichen
          Daten.
          <br />
          Die geltenden Datenschutzbestimmungen von PayPal sind unter
          https://www.paypal.com/de/webapps/mpp/ua/privacy-full abrufbar.
        </p>
      </div>
    </div>
  );
};

export default ZahlungsmethodeView;
