import { collection, getDocs, getFirestore } from "firebase/firestore";

import { app } from "./firestore";

export const getAllProducts = async () => {
  const db = getFirestore(app);
  const productRef = collection(db, "products");
  const querySnapshot = await getDocs(productRef);
  const allProducts: any[] = [];
  querySnapshot.forEach((doc) => {
    allProducts.push({ ...doc.data(), id: doc.id });
  });
  return allProducts;
};
