export const VersandmethodeView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Versandmethode</h3>
        <p>
          <span className="flex font-bold">Versandkosten Deutschland</span>
          Unter einem Warenwert von 69,99 € brutto bezahlen sie eine
          Versandkostenpauschale von lediglich 6,50 € inkl. MwSt.
        </p>
        <p>
          Ab einem Warenwert von 69,99 € brutto ist die Lieferung kostenfrei.
          (Wir versenden inklusive Transportversicherung).
        </p>
        <p>
          <span className="flex font-bold">
            Versandkosten innerhalb Europas
          </span>
          Aktuell versenden wir in 20 Ländern Europas. Für die Bestellung
          außerhalb Deutschlands finden Sie die Versandkosten unten auf der
          Tabelle.
        </p>
        <p className="font-bold">Preise und Versandkosten </p>
        <p>
          (1) Sämtliche Preisangaben in unserem Online-Shop sind Bruttopreise
          inklusive der gesetzlichen Umsatzsteuer und verstehen sich zuzüglich
          anfallender Versandkosten.
        </p>

        <p>
          (2) Die Versandkosten sind in unseren Preisangaben in unserem
          Online-Shop angegeben. Der Preis einschließlich Umsatzsteuer und
          anfallender Versandkosten wird außerdem in der Bestellmaske angezeigt,
          bevor Sie die Bestellung absenden.
        </p>
        <p>
          (3) Wenn wir Ihre Bestellung gemäß § 4 Abs. 1 durch Teillieferungen
          erfüllen, entstehen Ihnen nur für die erste Teillieferung
          Versandkosten. Erfolgen die Teillieferungen auf Ihren Wunsch,
          berechnen wir für jede Teillieferung Versandkosten. ​
        </p>
        <p>
          (4) Wenn Sie Ihre Vertragserklärung wirksam gemäß § 3 widerrufen,
          können Sie unter den gesetzlichen Voraussetzungen die Erstattung
          bereits bezahlter Kosten für den Versand zu Ihnen (Hinsendekosten)
          verlangen (vgl. zu sonstigen Widerrufsfolgen § 3 Abs. 3).
        </p>
      </div>
    </div>
  );
};

export default VersandmethodeView;
