import { MenuItem, Select } from "@mui/material";
import { ProductContents, ProductDetailProps } from "assets/Content";

import { BrowseBy } from "assets/Content";
import ProductCards from "components/ProductCards";
import { useState } from "react";

const filterProducts = (
  productContents: ProductDetailProps[],
  browseBy: BrowseBy
) =>
  productContents.filter(
    (product) =>
      product.productInfo?.type == browseBy || browseBy == BrowseBy.all
  );

export const ProductView = () => {
  const [browseBy, setBrowseBy] = useState(BrowseBy.all);
  const handleChange = (e: any) => {
    setBrowseBy(e.target.value);
  };
  return (
    <div className="min-h-screen">
      <div className="relative flex w-full h-full">
        <div className="hidden w-48 mt-40 ml-4 md:block lg:ml-8">
          <h3 className="w-full pb-4 mb-2 text-2xl font-semibold text-left border-b-2">
            Browse by
          </h3>
          <ul className="space-y-4 text-left">
            <li>
              <button
                className={browseBy == BrowseBy.all ? "underline" : ""}
                onClick={() => setBrowseBy(BrowseBy.all)}
              >
                All Products
              </button>
            </li>
            <li>
              <button
                className={browseBy == BrowseBy.yakju ? "underline" : ""}
                onClick={() => setBrowseBy(BrowseBy.yakju)}
              >
                Yakju Collection
              </button>
            </li>
            <li>
              <button
                className={browseBy == BrowseBy.takju ? "underline" : ""}
                onClick={() => setBrowseBy(BrowseBy.takju)}
              >
                Takju Collection
              </button>
            </li>
            <li>
              <button
                className={browseBy == BrowseBy.soju ? "underline" : ""}
                onClick={() => setBrowseBy(BrowseBy.soju)}
              >
                Soju Collection
              </button>
            </li>
            <li>
              <button
                className={browseBy == BrowseBy.set ? "underline" : ""}
                onClick={() => setBrowseBy(BrowseBy.set)}
              >
                Gift Set Collection
              </button>
            </li>
          </ul>
        </div>
        <div className="w-full mx-4 mt-32 md:mt-40 md:mx-8">
          <div className="mb-8 md:mb-16">
            <div className="flex mb-4 md:hidden">
              <h3 className="py-2 pr-2 ml-4 text-left w-fit">Browse By:</h3>
              <Select
                onChange={handleChange}
                value={browseBy}
                size="small"
                className="h-[30px] my-auto"
              >
                <MenuItem value={BrowseBy.all}>All</MenuItem>
                <MenuItem value={BrowseBy.yakju}>Yakju</MenuItem>
                <MenuItem value={BrowseBy.takju}>Takju</MenuItem>
                <MenuItem value={BrowseBy.soju}>Soju</MenuItem>
                <MenuItem value={BrowseBy.set}>Gift Set</MenuItem>
              </Select>
            </div>
            <h3 className="m-4 mt-0 text-3xl font-semibold text-left">
              {browseBy === BrowseBy.all
                ? "All Products"
                : browseBy === BrowseBy.set
                ? "Gift Set Collection"
                : `${
                    browseBy.charAt(0).toUpperCase() + browseBy.slice(1)
                  } Collection`}
            </h3>
            <p className="m-4 text-left">
              {browseBy == BrowseBy.all
                ? "Explore the essence of Korean tradition with our collection of Korean Traditional Wine and Spirits. They are crafted from ancient recipes and premium grains like rice, based on Korean traditional brewing process inherit the brewing know-hows that has been passed downs traditionally since ancient times. Discover Yakju (or Cheongju) for golden clarity as well as Takju for a cloudy as fermented beverages, Soju for a classic spirit experience as alcoholic beverages. Enjoy and experience the rich flavors and cultural heritage of Korean Wine and Spirits."
                : browseBy == BrowseBy.yakju
                ? "Discover Yakju, the exquisite top layer that emerges during the fermentation and filtration process. Crafted from rice, this golden-hued wine embodies elegance and purity. Yakju boasts a radiant appearance, ranging from a clear, pale straw hue to a light golden tone. Its vibrant clarity accentuates its refreshing taste and lively aroma, making every sip a delightful experience."
                : browseBy == BrowseBy.takju
                ? "Takju is a cloudy wine characterized by the sediment that forms during the fermentation process. It is crafted using a variety of grains, primarily rice, along with traditional Korean fermentation starters. What sets Takju apart is not just its ingredients, but also the method of filtering it. Its distinctive cloudy appearance enhances the drinking experience, offering a unique flavor and texture that enthusiasts appreciate."
                : browseBy == BrowseBy.soju
                ? "Soju, originating from Korea, is a distilled spirit crafted through a traditional Korean distillation process using high-quality local rice and nuruk. It undergoes a unique aging process, maturing for an extended period under low temperatures. Derived from the Korean words 'SO' meaning 'burn' and 'JU' meaning 'fermented- or alcoholic- beverages', Soju embodies the essence of distilled alcoholic beverages. It holds a significant place in Korean culture, being a staple in traditional ceremonies and rituals, and is now commonly enjoyed during social gatherings and meals."
                : ""}
            </p>
          </div>
          <ProductCards
            ProductContents={filterProducts(ProductContents, browseBy)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductView;
