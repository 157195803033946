export const JugendschutzView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Jugendschutz</h3>
        <p>
          Wir möchten im Sinne der Erfüllung des Jugendschutzgesetzes darauf
          hinweisen, dass wir keine alkoholischen Getränke an Personen unter 18
          Jahren verkaufen.
        </p>
        <p>
          Sofern Sie noch keine 18 Jahre alt sind, erhalten Sie deshalb keinen
          Zugang zu unserem Bestellsystem.
        </p>
        <p>
          Mit Ihrer Bestellung bestätigen Sie, dass Sie über 18 Jahre alt sind.
          Darüber hinaus sind die Zusteller verpflichtet, im Zweifel die
          Volljährigkeit durch das Vorzeigen des Personalausweises bestätigen zu
          lassen.
        </p>
        <p>(Referenz: AGBs § 8 Jugendschutzgesetz)</p>
      </div>
    </div>
  );
};

export default JugendschutzView;
