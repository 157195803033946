import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const CartBadge = (props: any) => {
  const { itemNumber } = props;
  return (
    <div>
      <Badge
        badgeContent={itemNumber}
        sx={{
          "& .MuiBadge-badge": {
            color: "#FFFFFF",
            backgroundColor: "#A89789",
          },
        }}
      >
        <ShoppingCartIcon fontSize="large" />
      </Badge>
    </div>
  );
};

CartBadge.propTypes = {
  itemNumber: PropTypes.number.isRequired,
};

export default CartBadge;
