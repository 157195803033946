//import { getAnalytics } from "firebase/analytics";
//import { getFirestore } from "firebase/firestore";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVffXWejxGJfnJ32HlPtcpRJizuF2ehUQ",
  authDomain: "sojuhalle.firebaseapp.com",
  projectId: "sojuhalle",
  storageBucket: "sojuhalle.appspot.com",
  messagingSenderId: "773705918187",
  appId: "1:773705918187:web:bbb200f993e2bdb6d280ae",
  measurementId: "G-MB0531S4P5",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
