import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { completeOrder, createOrder } from "paypal/paypal";
import {
  getShipping,
  getSubtotal,
  getTotal,
  isValidEmail,
  isValidLetters,
  isValidPhoneNumber,
  isValidPostalCode,
} from "utils/helper";
import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import LoadingOverlay from "react-loading-overlay-ts";
import OrderDetails from "components/cart/OrderDetails";
import { ProductContents } from "assets/Content";
import RemoveIcon from "@mui/icons-material/Remove";
import { createShippingOrder } from "firestore/orders";
import { getShippingInfo } from "firestore/shipping";
import { store } from "store";
import { useSelector } from "react-redux";

type State = { id: string; qty: number };

export const CartView = () => {
  const items: State[] = useSelector((state: any) => state.cart);
  const [searchParams, setSearchParams] = useSearchParams();
  const [completedOrder, setCompletedOrder] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [address, setAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    admin_area_1: "", // state
    admin_area_2: "", //city
    postal_code: "",
    country_code: "",
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [disabledQty, setDisabledQty] = useState(false);
  const [shippingInfo, setShippingInfo] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [shippingModalOpen, setShippingModalOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getShippingInfo().then((res: any[]) => {
      setShippingInfo([...res]);
    });
  }, []);
  useEffect(() => {
    if (items.length == 0) {
      alert("Cart is empty");
      navigate("/product");
    }
  }, [items]);

  const addOne = (product: string) => {
    store.dispatch({ type: "cart/addOne", payload: product });
  };
  const substractOne = (product: string) => {
    store.dispatch({ type: "cart/substractOne", payload: product });
  };

  const defineQuantity = (product: string, e: any) => {
    const newQty = Number(e.target.value);
    if (newQty > 0)
      store.dispatch({
        type: "cart/defineQuantity",
        payload: { product, qty: newQty },
      });
  };

  const continueOrder = async () => {
    setDisabledQty(true);
    const invalidFields = [];
    if (!isValidLetters(firstName)) {
      console.log("invalid first name");
      invalidFields.push("First Name");
    }
    if (!isValidLetters(lastName)) {
      console.log("invalid last name");
      invalidFields.push("Last Name");
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      console.log("invalid phone number");
      invalidFields.push("Phone Number");
    }
    if (!isValidEmail(email)) {
      console.log("Invalid email");
      invalidFields.push("Email");
    }
    if (!isValidLetters(address.admin_area_2)) {
      console.log("Invalid city");
      invalidFields.push("City");
    }
    if (!isValidLetters(address.admin_area_1)) {
      console.log("Invalid state");
      invalidFields.push("State");
    }
    if (!isValidPostalCode(address.postal_code)) {
      console.log("Invalid postal code");
      invalidFields.push("Postal Code");
    }

    setErrorFields([...invalidFields]);
    if (invalidFields.length > 0) return;

    setIsLoading(true);
    createOrder(
      address,
      items,
      email,
      phoneNumber,
      shippingInfo.find(({ country }) => country == selectedCountry)
    )
      .then(async (res: any) => {
        const response = await res.json();
        const order = response.id;
        setOrderId(order);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const removeProduct = (product: string) => {
    store.dispatch({ type: "cart/removeProduct", payload: product });
  };

  useEffect(() => {
    const token = searchParams.get("token");
    const payerId = searchParams.get("PayerID");
    if (token && payerId) {
      setIsProcessing(true);
      completeOrder(token)
        .then(async (res: any) => {
          if (res?.payer?.payer_id != payerId) {
            searchParams.delete("token");
            searchParams.delete("PayerID");
            navigate("/cart");
          } else {
            if (res.status == "COMPLETED") {
              createShippingOrder(res);
              setCompletedOrder(res);
              store.dispatch({ type: "cart/clearCart" });
              localStorage.removeItem("cart-items");
            } else console.log("SOMETHING WENT WRONG");
          }
          setIsProcessing(false);
        })
        .catch((err) => console.error(err));
    }
  }, [searchParams]);

  return (
    <div>
      <LoadingOverlay active={isProcessing} spinner text="Loading">
        <Modal
          open={shippingModalOpen}
          onClose={() => setShippingModalOpen(false)}
          disableAutoFocus={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-full max-w-lg m-auto bg-white rounded-lg max-w-screen sm:w-5/6 lg:w-1/2 h-fit"
        >
          <div className="w-full max-h-screen bg-white rounded-lg max-w-screen h-fit">
            <div className="mx-auto text-center h-fit">
              <h3 className="inline-block w-full my-2 text-xl text-center sm:my-8 sm:text-3xl md:text-4xl font-akrobat h-fit">
                Shipping Costs
              </h3>
              <p className="w-5/6 mx-auto mb-2 text-sm text-left h-fit sm:text-md md:text-lg">
                See below for shipping cost for available countries:
              </p>
              <ul className="w-11/12 mx-auto sm:w-3/4">
                {shippingInfo
                  .sort((a: any, b: any) => (a.country > b.country ? 1 : -1))
                  .map((shipping: any) => (
                    <li
                      key={shipping.country}
                      className="inline-flex w-full text-sm text-left sm:text-md"
                    >
                      <span className="w-[8rem]">{shipping.country}:</span>
                      <span className="w-[3rem]">
                        {`\u20AC${shipping.shippingCost.toFixed(2)}`}
                      </span>
                      {shipping?.minFreeShipping && (
                        <span className="ml-1 text-xs sm:text-sm w-fit">
                          (free shipping over {"\u20AC"}
                          {shipping.minFreeShipping})
                        </span>
                      )}
                    </li>
                  ))}
              </ul>
              <div className="inline-flex justify-center w-32 my-8">
                <button
                  onClick={() => setShippingModalOpen(false)}
                  className="w-full px-6 py-2 m-auto mx-2 text-white bg-black rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="relative flex w-full h-full min-h-screen">
          {completedOrder && completedOrder["status"] == "COMPLETED" ? (
            <div className="block w-full mx-auto mt-32">
              <h3 className="block w-full mx-auto my-8 text-xl font-bold">
                Thank you for your Order!
              </h3>
              <h3 className="block w-full mx-auto mb-6 text-lg font-bold">
                Order details
              </h3>
              <div className="inline-flex w-full mx-auto mb-24 lg:w-3/4">
                <OrderDetails order={completedOrder} />
              </div>
            </div>
          ) : (
            <div className="block w-full mx-auto mt-32">
              <div className="w-full mx-auto mb-24 sm:inline-flex lg:w-3/4">
                <div className="w-full h-full sm:mt-4 sm:block sm:w-1/2 lg:w-1/2">
                  <h3 className="block w-full mx-auto mb-2 text-xl font-semibold">
                    Cart
                  </h3>
                  <ul className="w-full h-full sm:max-h-[100vh] sm:overflow-scroll">
                    {items.map(({ id, qty }) => {
                      const product = ProductContents.find(
                        (product) => product.id.toString() == id
                      );
                      return (
                        product && (
                          <li className="flex w-full border-t-2 max-h-fit">
                            <div className="flex w-full h-full my-4">
                              <div className="flex mx-8 my-auto align-middle max-w-fit">
                                <img
                                  src={product.images[0]}
                                  className="block object-contain h-full min-h-[100px] min-w-[100px] w-48 "
                                />
                              </div>
                              <div className="block float-left w-full h-full mr-4">
                                <div className="w-full h-full mt-2 mb-8">
                                  <span className="float-left h-full text-lg font-semibold text-left">
                                    {product.name} ({product.nameKor})
                                  </span>
                                  <button
                                    className="float-right w-4 h-4"
                                    onClick={() => {
                                      removeProduct(id);
                                    }}
                                  >
                                    <span className="w-full h-full">X</span>
                                  </button>
                                </div>
                                <div className="inline-flex w-full mt-4">
                                  <span>{product.price + "\u20AC"}</span>
                                </div>
                                <div className="flex w-full mt-4 ml-0">
                                  <div className="inline-flex border-2 border-slate-400">
                                    <button
                                      className="mx-1 rounded-md disabled:opacity-20"
                                      onClick={() => substractOne(id)}
                                      disabled={
                                        items.filter(
                                          (item: State) => item.id == id
                                        )[0].qty < 2 || disabledQty
                                      }
                                    >
                                      <RemoveIcon />
                                    </button>
                                    <TextField
                                      type="number"
                                      className="float-left w-48 text-center max-w-[10vh] max-h-fit"
                                      size="small"
                                      value={qty}
                                      onChange={(e: any) =>
                                        defineQuantity(id, e)
                                      }
                                      sx={{
                                        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button":
                                          {
                                            "-webkit-appearance": "none",
                                            margin: "0",
                                          },
                                        "input[type=number]": {
                                          "-moz-appearance": "textfield",
                                        },
                                        "& fieldset": { border: "none" },
                                      }}
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                      disabled={disabledQty}
                                    />
                                    <button
                                      className="mx-1 rounded-md max-h-fit disabled:opacity-20"
                                      onClick={() => addOne(id)}
                                      disabled={disabledQty}
                                    >
                                      <AddIcon />
                                    </button>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <span className="float-right w-full mr-4 text-xl text-right">
                                    {(product.price * qty).toFixed(2) +
                                      "\u20AC"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
                <div className="w-full h-full mt-20 border-t-2 sm:block sm:w-1/2 lg:w-1/2 sm:border-t-0 sm:mt-0">
                  <div className="block w-5/6 h-full mx-auto mt-4">
                    {/*<form ref={form} onSubmit={sendEmail}>*/}
                    <h3 className="mb-4 text-xl font-semibold">
                      Customer Details
                    </h3>

                    <div className="flex justify-center w-full h-full gap-4 mb-4">
                      <TextField
                        type="text"
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        disabled={orderId != null}
                        error={
                          errorFields.includes("First Name") &&
                          !isValidLetters(firstName)
                        }
                      />
                      <TextField
                        type="text"
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        disabled={orderId != null}
                        error={
                          errorFields.includes("Last Name") &&
                          !isValidLetters(lastName)
                        }
                      />
                    </div>
                    <div className="flex justify-center w-full gap-4 mb-12">
                      <TextField
                        label="Phone Number"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        disabled={orderId != null}
                        error={
                          errorFields.includes("Phone Number") &&
                          !isValidPhoneNumber(phoneNumber)
                        }
                      />
                      <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={orderId != null}
                        error={
                          errorFields.includes("Email") && !isValidEmail(email)
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <h3 className="text-xl font-semibold">
                        Shipping Address Details
                      </h3>
                      <span className="text-sm">
                        * Currently, items can be only shipped within Germany
                      </span>
                    </div>
                    <div className="flex justify-center w-full h-full mb-4">
                      <TextField
                        label="Address Line 1 (Street Number/Name)"
                        //max 300
                        value={address.address_line_1}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            address_line_1: e.target.value,
                          })
                        }
                        className="w-full"
                        disabled={orderId != null}
                      />
                    </div>
                    <div className="flex justify-center w-full h-full mb-4">
                      <TextField
                        label="Address Line 2 (optional) (Suite/Apt number)"
                        //max 300
                        value={address.address_line_2}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            address_line_2: e.target.value,
                          })
                        }
                        className="w-full"
                        disabled={orderId != null}
                      />
                    </div>
                    <div className="flex justify-center w-full h-full gap-4 mb-4">
                      <TextField
                        label="Postal Code"
                        value={address.postal_code}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            postal_code: e.target.value,
                          })
                        }
                        className="w-full"
                        disabled={orderId != null}
                        error={
                          errorFields.includes("Postal Code") &&
                          !isValidPostalCode(address.postal_code)
                        }
                      />
                      <TextField
                        label="City"
                        value={address.admin_area_2}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            admin_area_2: e.target.value,
                          })
                        }
                        className="w-full"
                        disabled={orderId != null}
                        error={
                          errorFields.includes("City") &&
                          !isValidLetters(address.admin_area_2)
                        }
                      />
                    </div>
                    <div className="flex justify-center w-full h-full gap-4 mb-12">
                      <TextField
                        label="State"
                        value={address.admin_area_1}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            admin_area_1: e.target.value,
                          })
                        }
                        className="w-full"
                        disabled={orderId != null}
                        error={
                          errorFields.includes("State") &&
                          !isValidLetters(address.admin_area_1)
                        }
                      />

                      <FormControl fullWidth>
                        <InputLabel id="select-country">Country</InputLabel>
                        <Select
                          id="country"
                          label="Country"
                          labelId="select-country"
                          value={selectedCountry}
                          onChange={(e) => {
                            address.country_code = shippingInfo.find(
                              ({ country }) => country == e.target.value
                            ).countryCode;
                            setAddress({ ...address });
                            setSelectedCountry(e.target.value);
                          }}
                          autoWidth
                          className="text-lg text-left font-akrobat"
                        >
                          {shippingInfo
                            .sort((a, b) => (a.country < b.country ? -1 : 1))
                            .map((shippingCountry) => (
                              <MenuItem
                                key={shippingCountry.countryCode}
                                value={shippingCountry.country}
                              >
                                {shippingCountry.country}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="block w-3/4 mx-auto mb-8">
                      <div className="flex justify-between w-full mx-auto">
                        <span>Subtotal: </span>
                        <span> {getSubtotal(items).toFixed(2) + "\u20AC"}</span>
                      </div>
                      <div className="flex justify-between w-full mx-auto">
                        <span>Shipping: </span>
                        {shippingInfo
                          .map(({ countryCode }) => countryCode)
                          .includes(address?.country_code) ? (
                          <span>
                            {getShipping(
                              items,
                              shippingInfo.find(
                                ({ country }) => selectedCountry == country
                              )
                            ) > 0
                              ? getShipping(
                                  items,
                                  shippingInfo.find(
                                    ({ country }) => selectedCountry == country
                                  )
                                ).toFixed(2) + "\u20AC"
                              : "free"}
                          </span>
                        ) : (
                          <button
                            className="text-blue-500"
                            onClick={async () => {
                              setShippingModalOpen(true);
                            }}
                          >
                            See details for shipping
                          </button>
                        )}
                      </div>
                      <div className="flex justify-between w-full mx-auto text-lg">
                        <span>Total: </span>
                        <span>
                          {getTotal(
                            items,
                            shippingInfo.find(
                              ({ country }) => selectedCountry == country
                            )
                          ).toFixed(2) + "\u20AC"}
                        </span>
                      </div>
                      <div className="flex justify-between w-full mx-auto">
                        <span className="float-left w-full text-sm text-left">
                          Tax (VAT 19%) Included
                        </span>
                      </div>
                    </div>
                    <div className="rounded-3xl">
                      {!orderId ? (
                        <button
                          className="w-full p-3 mx-2 my-2 font-bold text-white transition duration-200 bg-tertiary rounded-3xl hover:bg-tertiaryhover"
                          onClick={() => {
                            continueOrder();
                          }}
                        >
                          {isLoading ? (
                            <span>Loading</span>
                          ) : (
                            <span>Continue</span>
                          )}
                        </button>
                      ) : (
                        <button className="w-full p-3 mx-2 my-2 font-bold text-white transition duration-200 bg-tertiary rounded-3xl hover:bg-tertiaryhover">
                          <Link
                            to={`${process.env.REACT_APP_PAYPAL_LINK}/checkoutnow?token=${orderId}`}
                          >
                            Checkout With Paypal
                          </Link>
                        </button>
                      )}
                      <span className="flex w-full text-sm">
                        * You must be over legal drinking age of 18 to purchase.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default CartView;

//https://www.namecheap.com/support/knowledgebase/article.aspx/10383/2176/how-to-set-up-a-dkim-record-for-private-email/
//const states = [
//  "BW",
//  "BY",
//  "BE",
//  "BB",
//  "HB",
//  "HH",
//  "HE",
//  "NI",
//  "MV",
//  "NW",
//  "RP",
//  "SL",
//  "SN",
//  "ST",
//  "SH",
//  "TH",
//];
