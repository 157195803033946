import { ProductContents } from "assets/Content";
type State = { id: string; qty: number };

export const getSubtotal = (items: State[]) =>
  Math.round(
    items.reduce((accum, current) => {
      const unitPrice = ProductContents.find(
        (product) => product.id.toString() == current?.id
      )?.price;
      if (unitPrice) return unitPrice * current.qty + accum;
      else return accum;
    }, 0) * 100
  ) / 100;

export const getTax = (items: State[]) =>
  Math.round(
    items.reduce((accum, current) => {
      const unitPrice = ProductContents.find(
        (product) => product.id.toString() == current?.id
      )?.price;
      if (unitPrice)
        return (
          (Math.round((0.19 * unitPrice * 100) / 1.19) / 100) * current.qty +
          accum
        );
      else return accum;
    }, 0) * 100
  ) / 100;
//  Math.round(getSubtotal(items) * 0.19 * 100) / 100;

export const getShipping = (items: State[], shippingCountry?: any) => {
  if (shippingCountry?.minFreeShipping) {
    return getSubtotal(items) < shippingCountry?.minFreeShipping
      ? shippingCountry?.shippingCost
      : 0;
  } else {
    return shippingCountry?.shippingCost ?? 6.5;
  }
};

export const getTotal = (items: State[], shippingCountry?: any) =>
  Math.round((getShipping(items, shippingCountry) + getSubtotal(items)) * 100) /
  100;

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );

export const isValidPhoneNumber = (phoneNumber: string) =>
  /^[0-9]*$/?.test(String(phoneNumber)) && phoneNumber.length > 7;

export const isValidAddress = (address: any) => {
  //address.address_line_1
  return true;
};

export const isValidPostalCode = (postalCode: string) =>
  String(postalCode).match(/^[0-9]*$/) && postalCode.length == 5;

export const isValidLetters = (name: string) =>
  name.split(" ").every((substr) => String(substr).match(/^[A-zÀ-ú]+$/)) &&
  name.length > 0;

export const getPerLiterPrice = (
  price: string | number | undefined,
  vol: string | number | undefined
) => {
  const PerLiterPrice = Number(price) / (Number(vol) / 1000);
  return `${PerLiterPrice.toFixed(2).toString().replace(".", ",")}\u20AC/l`;
};

export const isOnlyNumbers = (onlyNumber: string) =>
  /^[0-9]*$/.test(onlyNumber);
