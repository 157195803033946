import { collection, getDocs, getFirestore } from "firebase/firestore";

import { app } from "./firestore";

export const getAllLocations = async () => {
  const db = getFirestore(app);
  const locationRef = collection(db, "locations");
  const querySnapshot = await getDocs(locationRef);
  const allLocations: any[] = [];
  querySnapshot.forEach((doc) => {
    allLocations.push({ ...doc.data(), id: doc.id });
  });
  return allLocations;
};
