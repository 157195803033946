import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { Tab, Tabs } from "@mui/material";
import {
  GoogleAuthProvider,
  User,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPopup,
} from "@firebase/auth";
import { useEffect, useState } from "react";
import { app } from "firestore/firestore";
import Orders from "components/admin/Orders";
import Customers from "components/admin/Customers";
import Inventories from "components/admin/Inventories";

export const AdminView = () => {
  const auth = getAuth(app);
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [currentTab, setCurrentTab] = useState<number>(0);

  setPersistence(auth, browserLocalPersistence);

  const signIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    console.log(auth.currentUser);

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const user = result.user;
        setUser(result.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      } else {
        console.log("no user");
      }
    });
  }, [user]);

  return (
    <div className="flex w-full h-full min-h-screen mx-auto">
      <div className="w-full mt-2">
        {auth.currentUser ? (
          <div>
            <Tabs
              value={currentTab}
              onChange={(e, tabIndex) => setCurrentTab(tabIndex)}
            >
              <Tab label="Customer" value={0} />
              <Tab label="Order" value={1} />
              <Tab label="Inventory" value={2} />
            </Tabs>
            {currentTab === 0 && <Customers />}
            {currentTab === 1 && <Orders />}
            {currentTab === 2 && <Inventories />}
          </div>
        ) : (
          <button
            className="px-4 py-2 rounded-lg bg-tertiary text-primary"
            onClick={signIn}
          >
            Sign In
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminView;
