import { BrowseBy, ProductInfo } from "assets/Content";
import { useEffect, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImageGallery from "react-image-gallery";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ProductDetailProps } from "assets/Content";
import React from "react";
import { getPerLiterPrice } from "utils/helper";
import { store } from "store";
import { useParams } from "react-router-dom";

enum CollapsibleOpen {
  howToEnjoy = "howToEnjoy",
  howToPair = "howToPair",
  factSheet = "factSheet",
}

export const ProductDetail = ({ contents }: any) => {
  const [expanded, setExpanded] = useState<CollapsibleOpen | null>(null);
  const [qty, setQty] = useState<number>(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  const content = contents.find(
    (e: ProductDetailProps) => e.id.toString() === id
  );

  const addProduct = (product: string) => {
    store.dispatch({
      type: "cart/addNewProduct",
      payload: { id: product, qty },
    });
  };

  const images = content.images.map((image: string) => ({
    original: image,
    thumbnail: image,
    thumbnailClass: "float-left",
  }));

  const lineBreak = (text: string) =>
    text.split("\n").map((line: string, index: number) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  const getFactSheetInfo = (info: ProductDetailProps["productInfo"]) => {
    let drinkCategory;
    switch (info?.category) {
      case "Fermented":
        drinkCategory = "Wine/Fermented Beverages";
        break;
      case "Liqueurs":
        drinkCategory = "liqueurs/Alcoholic Beverages";
        break;
      case "Spirits":
        drinkCategory = "Spirits/Alcoholic Beverages";
        break;
      default:
        drinkCategory = "";
        break;
    }
    const text = `${drinkCategory}, ${info?.productVolumeML?.toFixed(
      0
    )}ml, Alc. ${info?.alcoholPercent}% Vol`;
    return text;
  };

  return (
    <div className="flex w-full mx-auto text-neutral-900 font-extralight font-akrobat">
      <div className="relative w-full h-full mx-auto mb-24 mt-36 lg:w-3/4 md:w-5/6">
        <div className="flex w-full h-full">
          <h3 className="w-full h-full mb-12 text-3xl md:text-4xl">
            {content.name} ({content.nameKor})
          </h3>
        </div>
        <div className="w-full mx-auto md:flex">
          <div className="w-5/6 h-full mx-auto md:mr-8 xl:w-1/3 md:w-1/2 min-h-fit">
            <ImageGallery
              items={images}
              showFullscreenButton={false}
              showNav={false}
              showPlayButton={false}
            />
          </div>
          <div className="block w-11/12 mx-auto md:w-1/2">
            <div className="w-full mx-auto mt-4">
              <div className="grid w-full grid-cols-3">
                <div className="col-span-1">
                  <div className="w-full">
                    <span className="flex w-full text-4xl">
                      {content.price.toString().replace(".", ",") + "\u20AC"}
                    </span>
                    <span className="flex w-full text-xs">
                      Tax (VAT 19%) Included
                    </span>
                  </div>
                  <span className="flex w-full text-2xl opacity-50">
                    {getPerLiterPrice(
                      content.price,
                      content.productInfo.productVolumeML
                    )}
                  </span>
                </div>
                {content.productInfo.type !== BrowseBy.set && (
                  <div className="w-full col-span-2">
                    <span className="block w-full mx-auto ml-0 text-lg text-right">
                      <LocationOnIcon /> {content.productInfo.producer}
                    </span>
                    <span className="block w-full mx-auto mr-0 text-lg text-right">
                      {content.productInfo.productVolumeML}mL (
                      {content.productInfo.alcoholPercent}% Vol)
                    </span>
                  </div>
                )}
              </div>
              <div className="inline-flex justify-between w-full my-4">
                <div className="inline-flex m-auto mx-2 min-w-fit h-fit">
                  <span className="mr-4">Quantity:</span>
                  <div className="inline-flex w-24 m-auto border">
                    <button
                      className="w-6 m-auto text-xl text-black rounded-md"
                      onClick={() => {
                        if (qty > 1) setQty(qty - 1);
                      }}
                    >
                      -
                    </button>
                    <span className="flex mx-2 my-auto">{qty}</span>
                    <button
                      className="w-6 m-auto text-xl text-black rounded-md"
                      onClick={() => {
                        setQty(qty + 1);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex w-fit">
                  <button
                    className="py-2 rounded-lg w-36 px-auto text-md h-fit min-w-fit bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                    onClick={() => addProduct(content.id.toString())}
                  >
                    {content.available ? (
                      <span>Add to Cart</span>
                    ) : (
                      <span>Pre-Order</span>
                    )}
                  </button>
                </div>
              </div>
              <p className="my-4 text-lg text-left">{content.desc}</p>
              <div className="block mt-8 mb-12 space-y-4">
                <div className="w-full border-b border-black">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.howToEnjoy
                          ? null
                          : CollapsibleOpen.howToEnjoy
                      )
                    }
                  >
                    <h3>How To Enjoy</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.howToEnjoy
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.howToEnjoy
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <p className="w-full h-full p-2 mb-6 text-left text-wrap">
                      {lineBreak(content.howTo.enjoy)}
                    </p>
                  </div>
                </div>
                <div className="w-full border-b border-black">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.howToPair
                          ? null
                          : CollapsibleOpen.howToPair
                      )
                    }
                  >
                    <h3>How To Pair</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.howToPair
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.howToPair
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <p className="w-full h-full p-2 mb-6 text-left text-wrap">
                      {lineBreak(content.howTo.pair)}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.factSheet
                          ? null
                          : CollapsibleOpen.factSheet
                      )
                    }
                  >
                    <h3>Fact Sheet</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.factSheet
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.factSheet
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <div className="w-full h-full p-2 mb-6 text-left text-wrap">
                      <span className="flex w-full font-bold">
                        Ingredients:
                      </span>
                      {content.productInfo.type === BrowseBy.set ? (
                        content.setProductInfo.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {info.ingredients?.join(", ")}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {content.productInfo.ingredients.join(", ")}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        Information:
                      </span>
                      {content.productInfo.type === BrowseBy.set ? (
                        content.setProductInfo.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {getFactSheetInfo(info)}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {getFactSheetInfo(content.productInfo)}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        Brewery:
                      </span>
                      {content.productInfo.type === BrowseBy.set ? (
                        content.setProductInfo.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {info.producer}, {info.region}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {content.productInfo.producer},{" "}
                          {content.productInfo.region}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        {content.name.includes("Yunguna")
                          ? "Distributor"
                          : "Importer"}
                      </span>
                      <span className="flex w-full">
                        Soju Halle GmbH, An den Pappeln 4a, 21521 Wohltorf,
                        Germany
                      </span>
                      <span className="flex w-full mt-4 text-xs">
                        * Images and labels may differ from the online images.
                        Although we strive to provide accurate representations,
                        variations may exist. Rest assured, the quality and
                        specifications remain consistent with our high
                        standards.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/*{content.taste.sweet * content.taste.rich * content.taste.flavor >
                0 && (
                <div className="w-11/12 mx-auto my-8">
                  <TasteSlider
                    degree={content.taste.sweet}
                    textMax="Sweet"
                    textMin="Dry"
                  />
                  <TasteSlider
                    degree={content.taste.rich}
                    textMax="Rich"
                    textMin="Light"
                  />
                  <TasteSlider
                    degree={content.taste.flavor}
                    textMax="Flavor"
                    textMin="Neat"
                  />
                </div>
              )}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
