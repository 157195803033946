import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import {
  isOnlyNumbers,
  isValidAddress,
  isValidEmail,
  isValidPhoneNumber,
} from "utils/helper";

import { Order } from "types/order";
import { Timestamp } from "firebase/firestore";
import { app } from "./firestore";

//export const createB2BOrder = async (order: Order) => {
//  const db = getFirestore(app);
//  const orderRef = collection(db, "orders");
//  await setDoc(doc(orderRef), {
//    order,
//  });
//};
const db = getFirestore(app);
const customerRef = collection(db, "customers");

export const getAllCustomers = async () => {
  const querySnapshot = await getDocs(customerRef);
  const allCustomers: any[] = [];
  querySnapshot.forEach((doc) => {
    allCustomers.push({ ...doc.data(), id: doc.id });
  });
  return allCustomers;
};

export const createCustomer = async (newCustomer: any) => {
  const {
    accountNumber,
    address,
    invoiceAddress,
    contactPerson,
    email,
    note,
    phoneNumber,
    name,
  } = newCustomer;
  const isCustomerValid =
    isOnlyNumbers(accountNumber) &&
    isValidAddress(address) &&
    isValidAddress(invoiceAddress) &&
    isValidEmail(email) &&
    isValidPhoneNumber(phoneNumber);
  if (isCustomerValid) {
    await setDoc(doc(customerRef), newCustomer);
    return true;
  } else {
    return false;
  }
};

export const deleteCustomer = async (customerId: string) => {
  await deleteDoc(doc(db, "customers", customerId));
};
