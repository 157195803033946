import { ProductDetailProps } from "assets/Content";
import { getPerLiterPrice } from "utils/helper";
import { store } from "store";
import { useNavigate } from "react-router-dom";

export const ProductCards = ({ ProductContents }: any) => {
  const navigate = useNavigate();
  const addProduct = (product: string) => {
    store.dispatch({
      type: "cart/addNewProduct",
      payload: { id: product, qty: 1 },
    });
  };
  return (
    <div className="w-full h-fit mb-72">
      <div className="w-full mx-auto h-fit lg:w-11/12">
        <div className="grid grid-cols-2 h-11/12 gap-x-4 gap-y-8 md:gap-y-12 md:grid-cols-3 lg:grid-cols-4">
          {ProductContents.map((item: ProductDetailProps) => {
            return (
              <div className="block w-full h-full col-span-1">
                <div className="relative inline-block w-full h-full mx-auto max-h-[50vw] md:max-h-[30vw] lg:max-h-[25vw]">
                  <img
                    className="block object-cover w-full h-full mx-auto rounded-xl"
                    src={item.images[0]}
                    alt={item.name}
                    draggable={false}
                  />
                  <div
                    className="absolute top-0 left-0 flex w-full h-full rounded-lg opacity-0 hover:opacity-100"
                    style={{ background: "rgba(0,0,0,0.5" }}
                  >
                    <div className="z-30 flex flex-col w-full h-full m-auto space-y-4 text-white sm:space-y-8">
                      <div className="w-full m-auto space-y-2 h-1/3 sm:h-1/2">
                        <h3 className="w-full mx-auto mt-4 text-lg sm:mt-8 font-akrobat">
                          {item.name}
                        </h3>
                        {item.nameKor && (
                          <h3 className="w-full mx-auto text-lg font-akrobat">
                            {item.nameKor}
                          </h3>
                        )}
                      </div>
                      <div className="w-full h-full my-auto space-y-2">
                        <button
                          className="block w-32 px-4 py-2 mx-auto mt-4 text-sm sm:mt-12 md:mt-4 lg:mt-8 sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                          onClick={() => navigate(`/product/${item.id}`)}
                        >
                          View Product
                        </button>
                        <button
                          className="block w-32 px-4 py-2 mx-auto text-sm sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                          onClick={() => addProduct(item.id.toString())}
                        >
                          {item.available ? (
                            <span>Add to Cart</span>
                          ) : (
                            <span>Pre-Order</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*</a>*/}
                </div>
                <div className="relative inline-block mx-auto h-fit w-fit">
                  <span className="block text-2xl font-akrobat">
                    {item.name}
                  </span>

                  <span className="block mx-auto text-xl w-fit">
                    {item.price.toString().replace(".", ",") + "\u20AC"}
                  </span>
                  <span className="block mx-auto text-sm w-fit">
                    {getPerLiterPrice(
                      item.price,
                      item.productInfo?.productVolumeML
                    )}
                  </span>
                  <span className="block mx-auto text-sm w-fit">
                    {item?.productInfo?.alcoholPercent?.toString() &&
                      item?.productInfo?.productVolumeML?.toString() &&
                      `Alcohol ${item.productInfo.alcoholPercent}% | 
                        ${item.productInfo.productVolumeML}mL`}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
