export const ImpressumView = () => {
  return (
    <div className="relative flex w-full h-full min-h-screen">
      <div className="w-3/4 h-full mx-auto mb-12 space-y-4 text-left lg:w-5/6 mt-36">
        <h3 className="text-3xl font-bold">Impressum</h3>
        <p>
          Die Soju Halle GmbH importiert und vertreibt traditionelle koreanische
          alkoholische Getränke, die den Vorschriften des koreanischen Gesetzes
          zur Förderung der traditionellen koreanischen Likörindustrie
          entsprechen.
        </p>
        <p className="font-bold">Soju Halle GmbH</p>
        <p>An den Pappeln 4a</p>
        <p>21521 Wohltorf, Deutschland</p>
        <p>Handelsregister: HRB 24430 HL</p>
        <p>Registergericht: Amtsgericht Lübeck</p>
        <p>USt-IdNr.: DE365228391</p>
        <p className="font-bold">Geschäftsführer</p>
        <p>Young Sam Hur</p>
        <p className="font-bold">Kontakt</p>
        <p>
          Das sind unsere Öffnungszeiten, bei Fragen zu Ihrer Bestellung
          verwenden Sie die Rufnummer 09:00 - 18:00
        </p>
        <p>Telefon: +49 (0)4104 9621236</p>
        <p>Email address: info@sojuhalle.com</p>
        <p className="font-bold">Rechtliche Hinweise:</p>
        <p className="font-bold">Verbraucherschlichtungsstelle hinweisen</p>
        <p>Außergerichtliche Streitbeilegung</p>
        <p>
          Bei Meinungsverschiedenheiten stehen Verbrauchern verschiedene
          außergerichtliche Streitb-eilegungsstellen zur Verfügung. Die
          europäische Union hat eine Online-Streitbeilegungsplattform unter dem
          Link
        </p>
        <a>
          https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE
        </a>
        <p>eingerichtet.</p>
        <p>
          Wir beabsichtigen, etwaige Meinungsverschiedenheiten oder
          Unstimmigkeiten direkt mit Ihnen zu klären. Im Übrigen sind wir auch
          nicht zur Teilnahme an einem Streitbeilegungsverfahren verpflichtet.
          Daher nehmen wir nicht an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teil.
        </p>
        <p className="pb-2 border-b border-black">
          Sollten Meinungsverschiedenheiten oder Unstimmigkeiten auftreten,
          wenden Sie sich bitte direkt an uns.
        </p>
        <p className="font-bold">Bild- und Textnachweis:</p>
        <p>Copyright © 2024 Soju Halle GmbH</p>
      </div>
    </div>
  );
};

export default ImpressumView;
