import batti_soju_1 from "./images/product/batti_soju/batti_soju_1.png";
import batti_soju_2 from "./images/product/batti_soju/batti_soju_2.png";
import batti_soju_3 from "./images/product/batti_soju/batti_soju_3.png";
import batti_soju_4 from "./images/product/batti_soju/batti_soju_4.png";
import batti_soju_5 from "./images/product/batti_soju/batti_soju_5.png";
import batti_soju_6 from "./images/product/batti_soju/batti_soju_6.png";
import damsoul_1 from "./images/product/damsoul/damsoul_1.png";
import damsoul_2 from "./images/product/damsoul/damsoul_2.png";
import damsoul_3 from "./images/product/damsoul/damsoul_3.png";
//import gyeoul_1 from "./images/product/gyeoul/gyeoul_1.png";
//import gyeoul_2 from "./images/product/gyeoul/gyeoul_2.png";
import leegangju_19_1 from "./images/product/leegangju_19/leegangju_19_1.png";
import leegangju_19_2 from "./images/product/leegangju_19/leegangju_19_2.png";
import leegangju_25_1 from "./images/product/leegangju_25/leegangju_25_1.png";
import leegangju_25_2 from "./images/product/leegangju_25/leegangju_25_2.png";
import nimome_1 from "./images/product/nimome/nimome_1.png";
import nimome_2 from "./images/product/nimome/nimome_2.png";
import nimome_3 from "./images/product/nimome/nimome_3.png";
import soju_yeoyoo_1 from "./images/product/soju_yeoyoo/soju_yeoyoo_1.png";
import soju_yeoyoo_2 from "./images/product/soju_yeoyoo/soju_yeoyoo_2.png";
import soju_yeoyoo_3 from "./images/product/soju_yeoyoo/soju_yeoyoo_3.png";
import soju_yeoyoo_4 from "./images/product/soju_yeoyoo/soju_yeoyoo_4.png";
import solsongju_1 from "./images/product/solsongju/solsongju_1.png";
import solsongju_2 from "./images/product/solsongju/solsongju_2.png";
import wangju_1 from "./images/product/wangju/wangju_1.png";
import wangju_2 from "./images/product/wangju/wangju_2.png";
import yakju_set_1 from "./images/product/yakju_set/yakju_set_1.png";
import yakju_set_2 from "./images/product/yakju_set/yakju_set_2.png";
import yangchon_chungju_1 from "./images/product/yangchon_chungju/yangchon_chungju_1.png";
import yangchon_chungju_2 from "./images/product/yangchon_chungju/yangchon_chungju_2.png";
import yangchon_chungju_3 from "./images/product/yangchon_chungju/yangchon_chungju_3.png";
import yangchon_chungju_4 from "./images/product/yangchon_chungju/yangchon_chungju_4.png";
import yunguna_gift_1 from "./images/product/yunguna_gift/yunguna_gift_1.png";
import yunguna_gift_2 from "./images/product/yunguna_gift/yunguna_gift_2.png";
import yunguna_makgeolli_1 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_1.png";
import yunguna_makgeolli_2 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_2.png";
import yunguna_makgeolli_3 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_3.png";
import yunguna_takju_1 from "./images/product/yunguna_takju/yunguna_takju_1.png";
import yunguna_takju_2 from "./images/product/yunguna_takju/yunguna_takju_2.png";
import yunguna_takju_3 from "./images/product/yunguna_takju/yunguna_takju_3.png";
import yunguna_yakju_1 from "./images/product/yunguna_yakju/yunguna_yakju_1.png";
import yunguna_yakju_2 from "./images/product/yunguna_yakju/yunguna_yakju_2.png";
import yunguna_yakju_3 from "./images/product/yunguna_yakju/yunguna_yakju_3.png";

export enum BrowseBy {
  all = "all",
  yakju = "yakju",
  takju = "takju",
  soju = "soju",
  set = "set",
}

export type ProductInfo = {
  producer?: string;
  name?: string;
  region?: string;
  productVolumeML?: number;
  alcoholPercent?: number;
  category?: "Fermented" | "Spirits" | "Liqueurs";
  type?: BrowseBy;
  ingredients?: string[];
};

export type ProductDetailProps = {
  available: boolean;
  id: number;
  name: string;
  nameKor: string;
  price: number;
  desc?: string;
  intro?: string;
  src?: string;
  images: string[];
  taste?: {
    sweet: number;
    rich: number;
    flavor: number;
  };
  productInfo?: ProductInfo;
  setProductInfo?: ProductInfo[];
  howTo?: {
    enjoy?: string;
    pair?: string;
  };
};

export const ProductContents: ProductDetailProps[] = [
  //{
  //  id: 0,
  //  available: true,
  //  name: "TEST",
  //  nameKor: "테스트",
  //  price: 1,
  //  intro: "into",
  //  desc: "desc",
  //  images: [nimome_1],
  //  productInfo: {
  //    producer: "producer",
  //    region: "region",
  //    category: "Fermented",
  //    type: BrowseBy.yakju,
  //    productVolumeML: 1,
  //    alcoholPercent: 1,
  //    ingredients: ["Water"],
  //  },
  //  howTo: {
  //    enjoy: "enjoy",
  //    pair: "pair",
  //  },
  //},
  {
    id: 1,
    available: true,
    name: "Nimome",
    nameKor: "니모메",
    price: 17.99,
    intro:
      "Experience the unique fusion of sweet and sour tangerine notes in our Korean alcohol. Immerse yourself in the perfect blend of savory Jeju grains and aromatic citrus flavors. Elevate your taste buds with this delightful combination that promises a one-of-a-kind drinking experience.",
    desc: "Discover Nimome, a unique Jeju blend with rice, volcanic rock water, and sun-dried tangerine peels. Savor the perfect mix of graininess, bitterness, and subtle sweetness from Jeju's finest tangerines. Nimome, meaning 'in your heart' in Jeju dialect, embodies the warm spirit of sharing. Elevate your moments with this fresh taste from Jeju Island",
    images: [nimome_1, nimome_2, nimome_3],
    taste: {
      sweet: 7,
      rich: 3,
      flavor: 6,
    },
    productInfo: {
      producer: "Jejusam",
      region: "Jeju Island, Republic of Korea",
      category: "Fermented",
      type: BrowseBy.yakju,
      productVolumeML: 375,
      alcoholPercent: 11,
      ingredients: [
        "Water",
        "Rice",
        "Yeast",
        "Enzyme",
        "Fructose syrup",
        "Mandarin peel",
        "Citric acid",
        "Steviol glycoside",
      ],
    },
    howTo: {
      enjoy:
        "The perfect aperitif in Korean cuisine. Enjoy it neat, on the rocks, or mix it 50% with tonic. Elevate your drinking experience with Nimome's versatile and refreshing options.\n\nNimome is enjoyed best chilled between 9-12\u00B0C",
      pair: "Nimome, infused with citrusy yuzu, emits a subtly sweet and tangy aroma, offering a sterilizing medicinal drink. It harmonizes the nutty taste of grains with the refreshing citrus notes of yuzu peel. After swallowing, it concludes with a pleasant bitterness, leaving a satisfying finish. \n\nTry to pair Nimome with Sushi or Fish & Chips for a uniquely delightful experience. The combination of Nimome and fresh seafood is unparalleled. Elevate your dining with this perfect harmony.",
    },
  },
  {
    id: 2,
    available: true,
    name: "Wangju",
    nameKor: "왕주",
    price: 17.99,
    intro:
      "Experience flavor of cultural heritage. Immerse yourself in various herbal flavor",
    desc: "Discover a fine spirit designated as National Important Cultural Heritage No. 56, even used in the royal rituals. Wangju is crafted from various medical herbs, which has long been enjoyed not just as a medical elixir but also as a delightful beverage. Currently, this product is being made by food master Nam Sang-ran.",
    images: [wangju_1, wangju_2],
    taste: {
      sweet: 5,
      rich: 7,
      flavor: 8,
    },
    productInfo: {
      producer: "Minsokwangju",
      region: "Nonsan, Republic of Korea",
      productVolumeML: 375,
      alcoholPercent: 13,
      category: "Fermented",
      type: BrowseBy.yakju,
      ingredients: [
        "Water",
        "Rice",
        "Yeast",
        "Enzyme",
        "Goji berry",
        "Wild chrysanthemum",
        "Pine shoots extract",
      ],
    },
    howTo: {
      enjoy:
        "Wangju is a complex drink with a refreshing and rich flavor profile. Initially, you'll taste a light sweetness, but as it goes down, you'll experience a nutty richness with a hint of bitterness lingering in your mouth. While there's a slight herbal or yeast aroma, it's not overpowering, making it suitable for most palates. \n\nWangju is enjoyed at its best between 9-12 degrees celsius",
      pair: "Wangju pairs perfectly with soy sauce-based dishes, offering a slightly sweet and warm flavor akin to alcohol.\n\nHave you ever tried Korean soy sauce-based Bulgogi? Bulgogi is a savory and slightly sweet Korean dish made with thinly sliced marinated beef, grilled to perfection. Try and enjoy the harmonious blend!",
    },
  },
  {
    id: 3,
    available: true,
    name: "Solsongju",
    nameKor: "솔송주",
    price: 18.99,
    intro:
      "Experience the mild scent of Korean pine needles and pine sprout. A sip of Solsongju will instantly transport you to a cool pine grove with its minty and refreshing mouthfeel?",
    desc: "Experience artisanal liquor with its great flavor and quality with 500 years old traditional legacy as the home brewed liquor of the Hadong clan of the Jeong family in Hamyang,. Solsongju is brewed with a new crop of rice from Hamyang, pristine pine needles, pine shoots and clear water from Jirisan Mountain",
    images: [solsongju_1, solsongju_2],
    taste: {
      sweet: 3,
      rich: 3,
      flavor: 4,
    },
    productInfo: {
      producer: "Solsongju",
      region: "Hamyang, Republic of Korea",
      productVolumeML: 375,
      alcoholPercent: 13,
      category: "Fermented",
      type: BrowseBy.yakju,
      ingredients: ["Water", "Rice", "Yeast", "Enzyme", "Pine shoots extract"],
    },
    howTo: {
      enjoy:
        "Experience light and fresh pine aroma balanced with rice that gives its depth and a hint of sweetness. It works beautifully in cocktails that use other herbs such as rosemary or thyme but also enjoyed chilled directly from a wine glass.\n\nEnjoy chilled between 9-12 degrees celsius",
      pair: "Solsongju, with its subtle and mild flavor profile, enhances the delicate taste of seafood dishes, adding an extra layer of richness. Additionally, it pairs beautifully with savory pancakes or aromatic herbal dishes, inviting you to savor each moment of enjoyment slowly.\n\nHave you ever tried Solsongju with raw fish? Solsongju will add to your seafood dish an additional layer of rich flavour.",
    },
  },
  {
    id: 4,
    available: false,
    name: "Yangchon Chungju",
    nameKor: "양촌 청주 (우렁이쌀)",
    price: 32.99,
    intro:
      "Explore the legacy of Yangchon Brewery, a cornerstone of Korean brewing since 1920. Now managed by the 3rd generation with the 4th generation in training, our brewery embraces sustainable practices, utilizing snails in rice fields to cultivate organic rice, eliminating the need for herbicides",
    desc: "For Yangchon Chungju, an interesting traditional technique is used for one of the main ingredients, rice. Rice producers release snails in the rice fields, and this results high quality rice without using any pesticides. Experience the craftsmanship of our Cheongju, brewed with rice grown sans herbicides. This brew boasts a touch of natural sweetness, imparting a rounded texture that elevates your tasting experience.",
    images: [
      yangchon_chungju_1,
      yangchon_chungju_2,
      yangchon_chungju_3,
      yangchon_chungju_4,
    ],
    taste: {
      sweet: 7,
      rich: 3,
      flavor: 6,
    },
    productInfo: {
      producer: "Yangchon Brewery",
      region: "Nonsan, Republic of Korea",
      productVolumeML: 500,
      alcoholPercent: 14,
      category: "Fermented",
      type: BrowseBy.yakju,
      ingredients: ["Water", "Rice", "Yeast", "Enzyme"],
    },
    howTo: {
      enjoy:
        "It's a drink that captures the natural nuttiness of rice. You'll taste a subtle sweetness at first, but it smoothly disappears down your throat, making it easy to enjoy. Moreover, its lingering gentle bitterness leaves a charming allure that keeps you coming back for more, sip after sip.\n\nServe chilled between 9-12 degrees celsius in white wine glasses. Elevate your dining with a taste of tradition from Yangchon Brewery.",
      pair: "Indulge in the natural sweetness of our Cheongju, delivering a rounded texture that harmonizes flawlessly with robust dishes such as BBQ and spicy cuisine. The sharp, clean finish of this drink effortlessly balances out any hint of bitterness in your food, offering a refreshing conclusion to your dining experience.\n\nHave you ever tried Jaeyukboggeum? Jaeyukboggeum, a spicy and savory Korean stir-fried pork dish, is perfectly complemented by the smooth and subtle sweetness of rice wine. Elevate your dining experience with this perfect pairing.",
    },
  },
  {
    id: 5,
    available: true,
    name: "Yunguna Yakju",
    nameKor: "윤구나 약주",
    price: 34.99,
    intro: "Yunguna Yakju intro",
    desc: "Step into the enchanting world of traditional Korean alcoholic beverages and you will discover the hidden gem known as Yakju. Regarded as the top layer of refined rice wine, yakju shines like liquid gold, capturing the essence of craftsmanship and cultural heritage. In Korea, Yakju is considered as one of the finest traditional wine.",
    images: [yunguna_yakju_1, yunguna_yakju_2, yunguna_yakju_3],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      producer: "Yunguna Brewery",
      region: "Copenhagen, Denmark",
      productVolumeML: 750,
      //alcoholPercent: 9,
      alcoholPercent: 13,
      category: "Fermented",
      type: BrowseBy.yakju,
      ingredients: ["Water", "Rice", "Yeast"],
    },
    howTo: {
      enjoy:
        "Yakju is the perfect choice for celebrating significant milestones such as anniversaries, promotions or achievements. Whether it's a private dinner party or an intimate gathering, Yakju sets the stage for meaningful conversations and shared moments.\n\nRecommended serving Temperature is between 9-12 degrees celsius",
      pair: "Yakju can be enjoyed on its own or paired with dishes like grilled meat, pickled greens and berries, fresh herbs, and desserts. It is also a popular gift during special occasions like weddings and birthdays. If you ever get the chance to try Yakju, make sure to savor its rich flavors and appreciate its rich cultural history.",
    },
  },
  {
    id: 6,
    available: true,
    name: "Yunguna Takju",
    nameKor: "윤구나 탁주",
    price: 29.99,
    intro: "Yunguna Takju intro",
    desc: 'Takju, often referred to as "cloudy rice wine," is a traditional Korean alcoholic beverage crafted by fermenting grains, primarily rice. It has a rich history dating back centuries and remains a beloved part of Korean culture. The word takju can be translated to mean "liquor with sediment" due to its characteristic hazy appearance resulting from the lees still present.',
    images: [yunguna_takju_1, yunguna_takju_2, yunguna_takju_3],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      producer: "Yunguna Brewery",
      region: "Copenhagen, Denmark",
      productVolumeML: 750,
      alcoholPercent: 13,
      category: "Fermented",
      type: BrowseBy.takju,
      ingredients: ["Water", "Rice", "Yeast"],
    },
    howTo: {
      enjoy:
        "Takju's versatile flavor profile makes it an ideal companion for festive gatherings, family reunions, holiday celebrations, or casual get-togethers among friends. Its light and refreshing qualities symbolize togetherness and shared happiness.\n\n Enjoy cold between 9-12 degrees celsius",
      pair: "Creamy and silky smooth, our Takju embodies refined excellence. With tropical flavors developed during fermentation, each sip offers subtle hints of exotic fruits, adding complexity to the journey.\n\nTakju goes well with both rice dishes and deep fried dishes or Korean pancakes. Have you tried Takju with Bibimbap? Bibimbap is Korea's iconic rice bowl packed with sautéed veggies, marinated beef, a sunny-side-up egg, and signature sweet-spicy Bibimbap sauce, it's a flavor explosion in every bite.",
    },
  },
  {
    id: 7,
    available: true,
    name: "Yunguna Makgeolli",
    nameKor: "윤구나 막걸리",
    price: 25.99,
    intro: "Yunguna Makgeolli intro",
    desc: "Makgeolli is characterized by its cloudy, milky appearance. The sediment of rice particles gives it a unique texture, distinguishing it from clear liquors. Some variations may have a more translucent appearance, but the traditional makgeolli is often unfiltered. Makgeolli holds a special place in the hearts of the people, embodying the rich cultural heritage and the art of fermentation.",
    images: [yunguna_makgeolli_1, yunguna_makgeolli_2, yunguna_makgeolli_3],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      producer: "Yunguna Brewery",
      region: "Copenhagen, Denmark",
      productVolumeML: 750,
      //alcoholPercent: 9,
      alcoholPercent: 11,
      category: "Fermented",
      //type: "Makgeolli",
      type: BrowseBy.takju,
      ingredients: ["Water", "Rice", "Yeast"],
    },
    howTo: {
      enjoy:
        "Indulge in the essence of Korean tradition with Yunguna's Makgeolli – more than just a drink, it's an experience. Bright, dry, tart, sour, and fizzy, our Makgeolli is expertly crafted for all to enjoy. Whether you're a seasoned enthusiast or new to Makgeolli, its approachable nature welcomes you into the tradition.\n\nAs rain falls and pans sizzle, Makgeolli emerges as a storyteller, weaving tales of tradition, warmth, and shared joy. Cheers to rainy days and the comforting embrace of Makgeolli! Experience the tradition today with Yunguna's.\n\nMakgeolli is enjoyed a bit more cold between 4-7 degrees celsius",
      pair: "Discover the perfect companion for any occasion: Makgeolli. Light, refreshing, and subtly bitter, it accentuates flavors and complements any meal.\n\nMakgeolli goes especially well with a well prepared Korean PaJeon or KimchiJeon. These are Korean Pancakes which are often enjoyed with Makgeolli.",
    },
  },
  //{
  //  id: 8,
  //available: true,
  //  name: "Gyeoul",
  //  nameKor: "겨울",
  //  price: 1.23,
  //  intro:
  //    'Introducing Soju Gyeoul, where "Gyeoul (겨울)" signifies Winter in Korean. Crafted during the winter season and aged until the following winter, its tastes mirrors the pristine allure of an untouched snowfield. Experience the essence of winter in every sip',
  //  desc: "Discover the authentic taste of soju with our distilled variant, meticulously crafted from rice. Immerse yourself in the true essence of soju, where the nutty and clean notes of rice create a distinct and unparalleled flavor profile. Elevate your soju experience with the genuine taste that sets it apart",
  //  images: [gyeoul_1, gyeoul_2],
  //  taste: {
  //    sweet: 4,
  //    rich: 4,
  //    flavor: 6,
  //  },
  //  productInfo: {
  //    producer: "Arirang Jujo",
  //    region: "Cheongyang, Republic of Korea",
  //    productVolumeML: 500,
  //    alcoholPercent: 25,
  //    category: "Spirits",
  //    type: "Soju",
  //    ingredients: ["Water", "Rice", "Yeast", "Enzyme"],
  //  },
  //  howTo: {
  //    enjoy:
  //      "Savor the optimal experience of Gyeoul when served cold. Enjoy a straight sip to appreciate its essence, then elevate your experience by trying it with a refreshing mix of tonic and lime. Explore the versatility of Gyeoul and discover the perfect blend for your palate.",
  //    pair: "Have you indulged in the rich flavors of Korean Barbeque? Enhance your culinary journey with Gyeoul, a perfect companion that offers a taste of Korean culture. Elevate your dining experience as Gyeoul complements the savory delights of Korean Barbeque, adding a touch of authenticity to your culinary exploration.",
  //  },
  //},
  {
    id: 9,
    available: true,
    name: "Leegangju 19",
    nameKor: "이강주",
    price: 14.99,
    intro:
      "Immerse yourself in Korea's rich history with Leegangju, a reserved spriit from the Joseon dynasty, now an intangible cultural property. Crafted from rice wine, distilled and matured with pear, ginger, tumeric, cinnamon, and honey",
    desc: "Discover the rich heritage of South Korea with Leegangju, a revered spirit from the Joseon dynasty. Recognized as an official intangible cultural property, this meticulously crafted traditional spirit undergoes a two-year aging process, blending rice wine with pear, ginger, turmeric, cinnamon, and honey. Indulge in the delightful fusion of a refreshing pear flavor, tangy ginger notes, delicate turmeric aroma, spicy cinnamon kick, and a hint of honey sweetness",
    images: [leegangju_19_1, leegangju_19_2],
    taste: {
      sweet: 6,
      rich: 6,
      flavor: 7,
    },
    productInfo: {
      producer: "Leegangju",
      region: "Jeonju, Republic of Korea",
      //productVolumeML: 500,
      productVolumeML: 350,
      alcoholPercent: 19,
      category: "Spirits",
      //type: "Liquor",
      type: BrowseBy.soju,
      ingredients: [
        "Water",
        "Rice",
        "Yeast",
        "Enzyme",
        "Pear",
        "Ginger",
        "Turmeric",
        "Cinnamon",
        "Honey",
      ],
    },
    howTo: {
      enjoy:
        "As time passes, the liquor develops a richer and more rounded scent. Best enjoyed chilled or over ice, Leegangju encapsulates the essence of Korean craftsmanship. Secure your bottle now and experience the tradition and flavors of Leegangju in the comfort of your own home.",
      pair: "Since the alcohol serves as a seasoning itself, it doesn't overpower the flavor of the dish but rather enhances it with its diverse taste profile. Additionally, the robustness from various flavors of the alcohol, along with the sharpness from spices like cinnamon and ginger, helps balance the richness of oily meats without overshadowing the taste of the food.\n\nHave you ever tried to pair Leegangju with curry wurst? Leegangju has a unique taste of its own, therefore leegangju goes well with dishes with sweet and strong sauce.",
    },
  },
  {
    id: 10,
    available: true,
    name: "Leegangju 25",
    nameKor: "이강주",
    price: 16.99,
    intro:
      "Immerse yourself in Korea's rich history with Leegangju, a reserved spriit from the Joseon dynasty, now an intangible cultural property. Crafted from rice wine, distilled and matured with pear, ginger, tumeric, cinnamon, and honey",
    desc: "Discover the rich heritage of South Korea with Leegangju, a revered spirit from the Joseon dynasty. Recognized as an official intangible cultural property, this meticulously crafted traditional spirit undergoes a two-year aging process, blending rice wine with pear, ginger, turmeric, cinnamon, and honey. Indulge in the delightful fusion of a refreshing pear flavor, tangy ginger notes, delicate turmeric aroma, spicy cinnamon kick, and a hint of honey sweetness",
    images: [leegangju_25_1, leegangju_25_2],
    productInfo: {
      producer: "Leegangju",
      region: "Jeonju, Republic of Korea",
      //productVolumeML: 500,
      productVolumeML: 350,
      alcoholPercent: 25,
      category: "Spirits",
      //type: "Liquor",
      type: BrowseBy.soju,
      ingredients: [
        "Water",
        "Rice",
        "Yeast",
        "Enzyme",
        "Pear",
        "Ginger",
        "Turmeric",
        "Cinnamon",
        "Honey",
      ],
    },
    howTo: {
      enjoy:
        "As time passes, the liquor develops a richer. Best enjoyed chilled or over ice, or mixed as cocktail. Our recommendation will follow on our Blog Section. Leegangju encapsulates the essence of Korean craftsmanship. Secure your bottle now and experience the tradition and flavors of Leegangju in the comfort of your own home.",
      pair: "Since the alcohol serves as a seasoning itself, it doesn't overpower the flavor of the dish but rather enhances it with its diverse taste profile. Additionally, the robustness from various flavors of the alcohol, along with the sharpness from spices like cinnamon and ginger, helps balance the richness of oily meats without overshadowing the taste of the food.",
    },
  },
  {
    id: 11,
    available: true,
    name: "Soju Yeoyoo",
    nameKor: "소주 여유",
    price: 26.99,
    intro:
      '"Yeoyu", meaning relaxation in Korean, perfectly encapsulates the essence of Soju, providing Koreans with a lberating escape from their busy lives. Embrace the spirit of leisure and unwind with Yeoyu Soju',
    desc: 'Enjoy a clean taste, highlighted by its soft and fruity rice flavor. The bottle design incorporates the Korean alphabet to spell out "Yeoyoo," adding a touch of cultural elegance to this exquisite Soju.\n\nYeoyoo in Korean means relaxation. It is meant to give relaxation to the busy people in the modern world. You can both feel this on the bottle design and also from the taste!',
    images: [soju_yeoyoo_1, soju_yeoyoo_2, soju_yeoyoo_3, soju_yeoyoo_4],
    taste: {
      sweet: 5,
      rich: 4,
      flavor: 5,
    },
    productInfo: {
      producer: "Yangchon Brewery",
      region: "Nonsan, Republic of Korea",
      productVolumeML: 350,
      alcoholPercent: 25,
      category: "Spirits",
      type: BrowseBy.soju,
      ingredients: ["Water", "Rice", "Yeast", "Enzyme"],
    },
    howTo: {
      enjoy:
        "As you take a sip, the subtle sweetness of rice gently caresses your palate. But it's only for a moment. After the initial taste, a vibrant surge of alcohol exhilarates your senses, almost like clearing your sinuses. Even after swallowing, a lingering warmth persists, confirming you've truly savored the drink.\n\nEnjoy chilled between 9-12 degrees celsius",
      pair: "Pair it with traditional Korean dishes, whether stir-fried or marinated. The sweet and rich flavor of rice soju complements Korean cuisine perfectly. Plus, its brightness effortlessly balances the richness of oily dishes, ensuring your meal's flavors shine through without any worry.\n\nHave you ever tried to make Korean Glass-Noodle Japchae? Japchae is a delightful Korean dish made with stir-fried glass noodles, vegetables, and occasionally beef or other proteins. Its savory-sweet flavor profile pairs wonderfully with the subtle sweetness of rice soju. Together, they create a harmonious dining experience that's sure to please your palate!",
    },
  },
  {
    id: 12,
    available: true,
    name: "Batti Soju",
    nameKor: "바띠 소주",
    price: 29.99,
    desc: 'Batti," a Jeju dialect term meaning "field," is a premium distilled liquor with 21% alcohol content, crafted with Jeju mandarin and Jeju honey. It blends the refreshing tartness of mandarins, the natural sweetness of honey, and the nutty aroma of premium distilled rice liquor with Jeju\'s natural underground spring water.',
    images: [
      batti_soju_1,
      batti_soju_2,
      batti_soju_3,
      batti_soju_4,
      batti_soju_5,
      batti_soju_6,
    ],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      producer: "Jejusam",
      region: "Jeju Island, Republic of Korea",
      productVolumeML: 350,
      alcoholPercent: 21,
      category: "Spirits",
      type: BrowseBy.soju,
      ingredients: ["Water", "Rice", "Yeast", "Enzyme", "Mndarin", "Honey"],
    },
    howTo: {
      enjoy:
        "Enjoy Batti in shots, or on the rocks or even mixed in cocktails. Soju has never tasted so sweet and so fresh, crafted with Jeju mandarin and Jeju honey!\n\nEnjoy chilled between 9-12 degrees celsius.",
      pair: "Soju goes well with Korean Barbeque with a grill table. Why not try Batti, when trying for your Korean BBQ? Batti offers a smooth finish, eliminating the bitter aftertaste often associated with traditional distilled liquor, making it a luxurious choice.",
    },
  },
  {
    id: 13,
    available: true,
    name: "Damsoul",
    nameKor: "담솔",
    price: 37.99,
    intro:
      "Experience the pine flavor of Damsoul exceptionally nuanced yet offers refreshing finish with hint of juniper and sancho pepper spice. Immerse yourself in the essence of nature as each sip reveals a delicate pine shoot aroma, a refreshing infusion of pine needles, gentle sweetness, and a silky smooth finish",
    desc: "Damsoul has been selected as one of \"the most recommended Spirits\" from Sojuhalle!\n\nDamsoul conveys the meaning of containing the 'soul of a master.' After undergoing vacuum distillation and aging at low temperatures for two years, it develops an even smoother taste. With the addition of pine needle concentrate and honey, it is classified as a liqueur rather than a distilled spirit.",
    images: [damsoul_1, damsoul_2, damsoul_3],
    taste: {
      sweet: 3,
      rich: 3,
      flavor: 4,
    },
    productInfo: {
      producer: "Solsongju",
      region: "Hamyang, Republic of Korea",
      productVolumeML: 500,
      alcoholPercent: 40,
      category: "Spirits",
      //type: "Liquor",
      type: BrowseBy.soju,
      ingredients: [
        "Water",
        "Rice",
        "Yeast",
        "Enzyme",
        "Pine shoots extract",
        "Sugar",
        "Honey",
        "Purified salt",
        "Citric acid",
      ],
    },
    howTo: {
      enjoy:
        "Enjoy this fine Damsoul on the rocks with splash of quality tonic water that accenturate cooling pine notes. Our recommendation is to taste Damsoul on the Rocks.",
      pair: "Damsoul can be enjoyed in special celebrations, festivals, social gathering, after-dinner drinks and even for a good mixed cocktail. We suggest that you try Damsoul with your favorite German Dish! What are your thoughts on this?",
    },
  },
  {
    id: 14,
    available: true,
    name: "Yunguna Gift Set",
    nameKor: "윤구나 선물 세트",
    price: 79.99,
    intro:
      "Our Yunguna Gift Set includes three bottles of our finest Yunguna creations: Makgeolli, Takju, and Yakju. It's perfect for enjoying these traditional Korean wines with friends and family. It also makes an excellent gift to share the authetic taste of Korea with those you appreciate",
    desc: "Our Yunguna Gift Set includes three bottles of our finest Yunguna creations: Makgeolli, Takju, and Yakju. It's perfect for enjoying these traditional Korean wines with friends and family. It also makes an excellent gift to share the authetic taste of Korea with those you appreciate",
    images: [yunguna_gift_1, yunguna_gift_2],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      type: BrowseBy.set,
      ingredients: ["Water", "Rice", "Yeast"],
      region: "Copenhagen, Denmark",
      producer: "Yunguna Brewery",
      productVolumeML: 750 * 3,
    },
    setProductInfo: [
      {
        name: "Yakju",
        productVolumeML: 750,
        alcoholPercent: 13,
        category: "Fermented",
        producer: "Yunguna Brewery",
        region: "Copenhagen, Denmark",
        type: BrowseBy.set,
        ingredients: ["Water", "Rice", "Yeast"],
      },
      {
        name: "Takju",
        productVolumeML: 750,
        alcoholPercent: 13,
        category: "Fermented",
        producer: "Yunguna Brewery",
        region: "Copenhagen, Denmark",
        type: BrowseBy.set,
        ingredients: ["Water", "Rice", "Yeast"],
      },
      {
        name: "Makgeolli",
        productVolumeML: 750,
        alcoholPercent: 11,
        category: "Fermented",
        producer: "Yunguna Brewery",
        region: "Copenhagen, Denmark",
        type: BrowseBy.set,
        ingredients: ["Water", "Rice", "Yeast"],
      },
    ],
    howTo: {
      enjoy:
        "Bring one or more bottles to your next casual gathering, celebration, dinner or date night. If you plan to drink all three, we recommend to start with Makgeolli, then Takju, and Yakju",
      pair: "Makgeolli - Makgeolli goes well with a well-made Pajeon (Korean pancake) \n Takju - Try Takju with a home made Bibimbap \n Yakju - After your main dish, enjoy Yakju with a slice of blue cheese",
    },
  },
  {
    id: 15,
    available: true,
    name: "Nimome & Solsongju Set",
    nameKor: "니모메 & 솔송주 선물 세트",
    price: 30.99,
    intro:
      "Our Summer Yakju Set includes two bottles of Yakju: Nimome and Solsongju. Enjoy Nimome's vibrant mandarin peel essence and Solsongju's refreshing pine needle extract.",
    desc: "Our Summer Yakju Set includes two bottles of Yakju: Nimome and Solsongju. Enjoy Nimome's vibrant mandarin peel essence and Solsongju's refreshing pine needle extract.",
    images: [yakju_set_1, yakju_set_2],
    taste: {
      sweet: 0,
      rich: 0,
      flavor: 0,
    },
    productInfo: {
      type: BrowseBy.set,
      ingredients: ["Water", "Rice", "Yeast"],
      productVolumeML: 375 * 2,
    },
    setProductInfo: [
      {
        name: "Nimome",
        productVolumeML: 375,
        producer: "Jejusam",
        region: "Jeju Island, Republic of Korea",
        alcoholPercent: 11,
        category: "Fermented",
        type: BrowseBy.set,
        ingredients: [
          "Water",
          "Rice",
          "Yeast",
          "Enzyme",
          "Fructose syrup",
          "Mandarin peel",
          "Citric acid",
          "Steviol glycoside",
        ],
      },
      {
        name: "Solsongju",
        productVolumeML: 375,
        producer: "Solsongju",
        region: "Hamyang, Republic of Korea",
        alcoholPercent: 13,
        category: "Fermented",
        type: BrowseBy.set,
        ingredients: [
          "Water",
          "Rice",
          "Yeast",
          "Enzyme",
          "Pine shoots extract",
        ],
      },
    ],
    howTo: {
      enjoy:
        "Bring these bottles with one bottle of tonic to your next football game. Try out our easy and exciting cocktail recipe with these two drinks! Of course, you can enjoy them as a cold serving wine, a perfect match in a gathering.",
      pair: "Solsongju – Are you down for a Korean Barbeque? Have a bottle of Solsongju with you\nNimome – Try out our Nimome with sushi",
    },
  },
  //{
  //  id: 13,
  //available: true,
  //  name: "Gamhongro",
  //  nameKor: "감홍로",
  //price:1.23,
  //  intro:
  //    "Herbal liquor of the Joseon Dynasty made by rice and millet. Experience 7 herbal flavors which are Longan, cinnamon, orange peel, clover buds, ginger, licorice, lithospermum erythrorhizon root",
  //  desc: "Experience one of the three best traditional liquor, made by distilling liquor made from rice mixing the steeped Korean medicinal herbs",
  //  src: gamhongro_1,
  //  images: [gamhongro_3, gamhongro_4],
  //  taste: {
  //    sweet: 5,
  //    rich: 6,
  //    flavor: 8,
  //  },
  //  productInfo: {
  //    producer: "Yeoncheon Yangjo",
  //    region: "Paju, Republic of Korea",
  //    productVolumeML: 375,
  //    alcoholPercent: 40,
  //    category: "Spirits",
  //    type: "Liquor",
  //    ingredients: [
  //      "Water",
  //      "Rice",
  //      "Yeast",
  //      "Enzyme",
  //      "Dried orange peel",
  //      "Cinnamon",
  //      "Longan",
  //      "Violet-root cromwell",
  //      "Licorice",
  //      "Ginger",
  //      "Clove",
  //    ],
  //  },
  //  howTo: {
  //    enjoy:
  //      "Enjoy on the rock with ice or mix with warm water for greater flavor.",
  //    pair: "Gamhongro pairs well with vanilla icecream",
  //  },
  //},
];
