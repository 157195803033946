export const NotificationContent = (productName: string, qty?: number) => {
  return (
    <>
      <div className="w-full min-h-[50px] bg-tertiary flex my-auto rounded-lg">
        <span className="w-full h-full my-auto text-lg text-center text-primary">
          {productName}({qty}) added to cart
        </span>
      </div>
    </>
  );
};

export default NotificationContent;
