import { useEffect, useState } from "react";

import AGBsText from "./AGBsText";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import impressumText from "./ImpressumText";
import instagramLogo from "../../assets/images/navigation/instagram_logo.png";
import logo from "../../assets/images/navigation/logo_transparent.png";

export const Footer = () => {
  return (
    <div className="w-full h-full border-t-2 bg-secondary">
      <div className="flex w-full mx-auto my-4 sm:w-11/12 md:w-5/6 lg:w-3/4">
        <img src={logo} className="block w-16 mr-8" />
        <div className="grid w-full grid-cols-2">
          <div className="w-full col-span-1">
            <Link to="/impressum" className="flex">
              <span>Impressum</span>
            </Link>
            <Link to="/agb" className="flex">
              <span>AGB</span>
            </Link>
            <Link to="/datenschutzerklarung" className="flex">
              <span>Datenschutzerklarung</span>
            </Link>
          </div>
          <div className="w-full col-span-1">
            <Link to="/zahlungsmethode" className="flex">
              <span>Zahlungsmethode</span>
            </Link>
            <Link to="/versandmethode" className="flex">
              <span>Versandmethode</span>
            </Link>
            <Link to="/jugendschutz" className="flex">
              <span>Jugendschutz</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full">
        <span className="m-4 w-fitfull font-akrobat">
          ©2024 Soju Halle GmbH.
        </span>
        <div className="w-20 my-auto max-w-fit h-fit">
          <a href="https://www.instagram.com/soju_halle/" target="_blank">
            <img src={instagramLogo} className="w-8 h-full" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
